import { useState } from 'react';

//components
import { Box } from '@mui/material';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Select from 'react-select';

//styles
import styles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';

//icons
import add from '../../assets/img/icons/add-check.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import ordDownArrow from '../../assets/img/icons/ordDownArrow.svg';
import ordUpArrow from '../../assets/img/icons/ordUpArrow.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

//hooks
import { useSelector } from 'react-redux';

// helpers
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetMethod, usePostMethod } from '../../Hooks';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import {
  formatteDocument,
  customSwaltAlertAsistencial,
  isEmptyOrUndefined,
  loader,
  today,
} from '../../helpers';
import { formatNumberWithoutDecimals } from '../../helpers/numberFormatting'
import { NewPopover } from '../../shared';

const OrdersPage = () => {
  const store = useSelector(state => state);
  const siteId = store.userReducer.site;
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const idUser = store?.loginReducer?.user_data.id;
  const bussines_name = store.loginReducer.currentAccount?.bussines_name;
  const history = useHistory();

  const [currentTargetPopover, setCurrentTargetPopover] = useState(null);
  const [search, setSearch] = useState('');

  const [dataCancellation, setDataCancellation] = useState({
    cancelId: '',
    cancelObservation: '',
    isOpen: false,
  });

  const onClearDataCancellation = () => {
    setDataCancellation({
      cancelId: '',
      cancelObservation: '',
      isOpen: false,
    });
  };

  const [data, setData] = useState({
    trigger: 0,
    filters: {
      orderTypeId: '',
      dateMax: '',
      dateMin: '',
      search: '',
      generalResponse: 0,
      partialPayments: 0,
      page: 1,
      perpage: 10,
      eaccount,
    },
  });

  const [listProviderOrders, setListProviderOrders] = useState({
    message: '',
    rowTotal: 0,
    status: 0,
    title: '',
    results: [],
  });

  //////// HTTPS GETS ////////////
  const { load: loaderListProviderOrders, trigger: getListProviderOrders } = useGetMethod();

  const {
    results: listProviderOrdersTypes,
    load: loaderListProviderOrdersTypes,
    trigger: getListProviderOrdersTypes,
  } = useGetMethod();

  const { trigger: getCashClosingConfig, load: loadCashClosingConfig } = useGetMethod();

  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason,
  } = useGetMethod();

  //////// HTTPS POST ////////////
  const { load: loaderPostOnCancelOrders, trigger: postOnCancelOrders } = usePostMethod();

  const { load: loaderPostOnCancelSubscription, trigger: postOnCancelSubscription } =
    usePostMethod();

  const { filters, trigger } = data;

  const onChangeTrigger = () => setData(state => ({ ...state, trigger: state.trigger + 1 }));

  const onCancelOrden = ({ data: params, fullData }) => {
    if (isEmptyOrUndefined(dataCancellation?.cancelId)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un motivo de anulación valido',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(dataCancellation?.cancelObservation?.trim())) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite una observación valida',
        showCancelButton: false,
      });
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se anulará la orden número ${params?.sequence} al paciente: ${fullData?.patient?.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postOnCancelSubscription({
          url: `/medical/admissions/provider-orders/${fullData?.id}/payments/`,
          token: token,
          method: 'POST',
          body: {
            eaccount,
            userId: idUser,
            sequence: params?.sequence,
            cancelObservation: dataCancellation?.cancelObservation,
            cancelId: dataCancellation?.cancelId,
          },
          doAfterSuccess: response => {
            if (response?.success) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha anulado la orden ${params?.sequence} del paciente ${fullData?.patient?.name}`,
                showCancelButton: false,
              });

              onClearDataCancellation();
              setData(state => ({ ...state, trigger: state.trigger + 1 }));
            }
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message || 'error x',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const handleCancelOrders = item => {
    if (isEmptyOrUndefined(dataCancellation?.cancelId)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un motivo de anulación valido',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(dataCancellation?.cancelObservation?.trim())) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite una observación valida',
        showCancelButton: false,
      });
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se anulará la orden número ${item?.prefixOrder}  al paciente: ${item?.patient?.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postOnCancelOrders({
          url: `/medical/admissions/provider-orders/${item?.id}/`,
          token: token,
          method: 'POST',
          body: {
            eaccount,
            userId: idUser,
            cancelObservation: dataCancellation?.cancelObservation,
            cancelId: dataCancellation?.cancelId,
          },
          doAfterSuccess: response => {
            if (response?.success) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha anulado la orden número ${item?.prefixOrder} del paciente: ${item?.patient?.name}`,
                showCancelButton: false,
              });

              onClearDataCancellation();
              setData(state => ({ ...state, trigger: state.trigger + 1 }));
            }
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message || 'error x',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const renderAccordion = ({ data, fullData }) => {
    let total = 0;

    return (
      <tr id='tdWhite' className=' animate__animated animate__fadeIn'>
        <td colSpan={12} className='py-4 px-2'>
          <table style={{ fontFamily: 'PT Sans Caption' }}>
            <tr className='tableNewStyle'>
              <th className='text-start px-2'>Transacción</th>
              <th className='text-center px-2'>Fecha</th>
              <th className='text-start px-2'>Forma de pago</th>
              <th className='text-end px-2'>Total</th>
              <th className='text-end'></th>
            </tr>

            {data?.map((item, key) => {
              total += item?.total;
              return (
                <tr key={key} className='tableNewStyle'>
                  <td className='text-start px-2 col-2'>{item?.sequence || '-'}</td>
                  <td className='text-center px-2 col-2'>{item?.date || '-'}</td>

                  <td className='text-start px-2 col-2'>
                    {item?.deposits.map(el => el?.name)?.join() || '-'}
                  </td>
                  <td className='text-end px-2 col-2'>
                    {item?.total ? formatNumberWithoutDecimals(item?.total) : '-'}
                  </td>
                  <td className='text-end col-2'>
                    <CustomPopupExtend
                      position='right center'
                      className={`align-self-end`}
                      triggerClass={`${styles.hoverPopup} `}
                      triggerSrc={threeDots}
                      extraButtons={[
                        {
                          text: 'Anular orden',
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => {
                            setDataCancellation(state => ({
                              ...state,
                              isOpen: true,
                              data: item,
                              fullData,
                            }));
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              );
            })}

            <tr className={`fw-bold ${styles.f14}`}>
              <td className='text-start px-2 col-2'>{''}</td>
              <td className='text-center px-2 col-2'>{''}</td>
              <td className='text-start px-2 col-2'>{''}</td>
              <td className='text-start px-2 col-2'>{''}</td>
              <td className='text-end px-2 col-2'>
                {total ? formatNumberWithoutDecimals(total) : '-'}
              </td>
              <td className='text-center px-2 col-2'>{''}</td>
            </tr>
          </table>
        </td>
      </tr>
    );
  };

  //table
  const headers = [
    {
      title: <>&nbsp;&nbsp;</>,
      className: `ps-2 `,
    },
    {
      title: 'Tipo',
      className: `text-center col-1`,
    },
    {
      title: 'No. Orden',
      className: `px-2 text-center col-1`,
    },
    {
      title: 'Fecha',
      className: `px-2 text-center col-2`,
    },
    {
      title: 'Paciente',
      className: `px-2 text-start col-2`,
    },
    {
      title: `Empresa`,
      className: `px-2 text-start col-1`,
    },
    {
      title: `Contrato`,
      className: `px-2 text-start col-2`,
    },
    {
      title: `Admisionista`,
      className: `px-4 text-start`,
    },
    {
      title: `Total`,
      className: `text-end px-2 col-1`,
    },

    {
      title: 'Estado',
      className: `px-2 text-center`,
    },
    {
      title: <>&nbsp;</>,
      className: 'text-start',
    },
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <tr
              key={item?.id}
              className={`hover-table-row pointer ${item?.toggle ? 'accordionStyles' : ''}`}
              onClick={() => {
                // listProviderOrders?.results[index]?.toggle = !item?.toggle

                let format = listProviderOrders?.results;
                format[index] = { ...format[index], toggle: !format[index]?.toggle };

                setListProviderOrders({
                  ...listProviderOrders,
                  results: format,
                });
              }}
            >
              <td className='ps-2'>
                <img src={!item?.toggle ? ordDownArrow : ordUpArrow} alt='icon' />
              </td>

              <td className={`text-center`}>
                {item?.iconUrl ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                      <Tooltip>
                        <div style={{ opacity: 1, background: '#fff', color: '#697387' }}>
                          {item?.typeName}
                        </div>
                      </Tooltip>
                    }
                  >
                    <img src={item?.iconUrl} alt='type-order' height={22} width={22} />
                  </OverlayTrigger>
                ) : (
                  '-'
                )}
              </td>

              <td className={`text-center px-2`}>{item?.prefixOrder || '-'}</td>

              <td className={`text-center px-2 mx-2`}>
                <span>{moment(item?.date).format('DD/MM/YYYY') || '-'}</span>
                <br />
                <span>{moment(item?.date).format('h:mm a') || '-'}</span>
              </td>

              <td className={`text-start px-2 `}>
                <span>{item?.patient?.name || '-'}</span>
                <br />
                <span style={{ color: '#A2A3AB' }}>
                  {`${item?.patient?.docType || '-'} ${ formatteDocument(item?.patient?.doc).format || '-'}`}
                </span>
              </td>

              <td className={`text-start px-2`}>{item?.companyName || '-'}</td>

              <td className={`text-start px-2`}>{item?.contractName || '-'}</td>

              <td className={`text-center`}>{item?.admisionist?.name || '-'}</td>

              <td className={`text-end px-2`}>
                <div style={{ minWidth: '80px' }}>
                  {item?.totalAmount ? formatNumberWithoutDecimals(item?.totalAmount) : '-'}
                </div>
              </td>

              <td className={`text-center px-2`}>
                <div
                  style={{
                    color: `${item?.status?.fontColor}`,
                    backgroundColor: `${item?.status?.background}`,
                    borderRadius: '10px',
                    padding: '2px 10px',
                    width: '100px',
                  }}
                >
                  {item?.status?.name || '-'}
                </div>
              </td>

              <td className={`text-start`}>
                <CustomPopupExtend
                  position='right center'
                  className={`align-self-end`}
                  triggerClass={`${styles.hoverPopup} `}
                  triggerSrc={threeDots}
                  extraButtons={[
                    {
                      text: 'Ver detalle',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        history.push({
                          pathname: `/admision/ordenesServicios/${item?.id}`,
                          state: {
                            ...item,
                            currentAccount: { bussines_name },
                            provider: true,
                          },
                        });
                      },
                    },
                    {
                      text: 'Anular',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        setDataCancellation(state => ({
                          ...state,
                          isOpen: true,
                          item,
                        }));

                        // handleCancelOrders(item)
                      },
                    },
                  ]}
                />
              </td>
            </tr>

            {item?.toggle && renderAccordion({ data: item?.bills, fullData: item })}
          </>,
        );
      });
    }
    return tempList;
  };

  useEffect(() => {
    getListProviderOrdersTypes({
      token: token,
      url: '/medical/admissions/provider-orders/types',
    });

    getListCancellationReason({
      url: '/medical/generals/cancellationReason/',
      token: token,
    });
  }, [token, getListProviderOrdersTypes, getListCancellationReason]);

  useEffect(() => {
    getListProviderOrders({
      token: token,
      objFilters: { ...filters },
      url: '/medical/admissions/provider-orders/',
    }).then(res => {
      if (res?.success) {
        const format = res?.results?.map(el => ({ toggle: false, ...el }));

        setListProviderOrders({
          ...res,
          results: format,
        });
      }
    });
  }, [trigger, filters, getListProviderOrders, token]);

  return (
    <>
      {(loaderListProviderOrders ||
        loaderListProviderOrdersTypes ||
        loaderPostOnCancelOrders ||
        loaderPostOnCancelSubscription ||
        loaderListCancellationReason ||
        loadCashClosingConfig) &&
        loader}

      <Row className='d-flex px-1'>
        <Col xs={2} className={`px-0 align-self-end`}>
          <Form.Group className='px-2' controlId='ordType'>
            <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
              <span>&nbsp;Tipo de orden</span>
            </Form.Label>

            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={
                listProviderOrdersTypes?.results?.length
                  ? [
                      { label: 'Seleccionar...', value: '' },
                      ...(listProviderOrdersTypes?.results?.map(el => ({
                        label: el?.pot_name,
                        value: el?.pot_id,
                      }))) ?? [],
                    ]
                  : []
              }
              className='text-secondary '
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              onChange={({ value }) => {
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, orderTypeId: value },
                }));
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={2} className={`px-0 align-self-end`}>
          <Form.Group className='px-2' controlId='ordType'>
            <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
              <span>&nbsp;Fecha desde</span>
            </Form.Label>
            <input
              className={`ord-roundInput2`}
              id='date-start'
              max={today()}
              style={filters.dateMin ? { color: '#1a538d' } : {}}
              type='date'
              value={filters.dateMin}
              onChange={e => {
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, dateMin: e.target.value, dateMax: '' },
                }));
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={2} className={`px-0 align-self-end`}>
          <Form.Group className=' px-2' controlId='ordType'>
            <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
              <span>&nbsp;Hasta</span>
            </Form.Label>
            <input
              className={`ord-roundInput2`}
              disabled={!filters.dateMin}
              id='date-end'
              max={today()}
              min={filters.dateMin || ''}
              style={filters.dateMax ? { color: '#1a538d' } : {}}
              type='date'
              value={filters.dateMax}
              onChange={e => {
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, dateMax: e.target.value },
                }));
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={4} className='align-self-end'>
          <Form.Group className=' text-start' controlId='fName'>
            <div className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
              <input
                className={`ord-roundInput ${styles.appInputDate} w-100`}
                type='text'
                placeholder='Escribe aquí para buscar'
                autoComplete='off'
                onChange={e => {
                  setSearch(e.target.value);
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, page: 1, search },
                    }));
                    onChangeTrigger();
                  }
                }}
              />
              <img
                src={ordBlueSearch}
                className='pointer'
                alt='buscar'
                onClick={() => {
                  setData(state => ({
                    ...state,
                    filters: { ...state.filters, page: 1, search },
                  }));
                  onChangeTrigger();
                }}
              />
            </div>
          </Form.Group>
        </Col>

        <Col id='newOrdenHover' className={`d-flex mt-4 ${styles.appDarkBlueText}`}>
          <div
            className={`d-flex align-self-end ms-auto justify-content-end gap-3 ${styles.colorNewOrderHover}`}
            onClick={event =>
              setCurrentTargetPopover(currentTargetPopover ? null : event.currentTarget)
            }
          >
            <div style={{ fontWeight: 'bold' }}>Nueva orden</div>
            <img src={add} style={{ marginRight: '5px' }} alt='add' />
          </div>
        </Col>
      </Row>

      <OrdTable
        shadow
        headers={headers}
        hasChildren={true}
        oneHeadDarkBlue={false}
        lowerCase={false}
        paginate={{
          activePage: filters.page,
          totalPages: listProviderOrders?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          showTextDetails: true,
          onChangePage: async e => {
            setData(state => ({
              ...state,
              filters: { ...state?.filters, page: e },
              trigger: state.trigger + 1,
            }));
          },
        }}
      >
        {formatData(listProviderOrders?.results || [])}
      </OrdTable>

      <NewPopover
        currentTarget={currentTargetPopover}
        listOptions={listProviderOrdersTypes?.results?.map(e => ({
          text: e?.pot_name,
          onClick: () => {
            getCashClosingConfig({
              url: `/medical/admissions/cashClosingConfig/`,
              objFilters: { userId: idUser, siteId, eaccount },
              token: token,
            }).then(res => {
              if (res?.success) {
                if (res?.results?.hasPendingCashClosing) {
                  customSwaltAlertAsistencial({
                    icon: 'warning',
                    title: `Intenta de nuevo`,
                    text: 'Para poder seguir generando órdenes, es necesario que realice un cierre parcial',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                  });
                } else {
                  history.push({
                    pathname: '/admision/ordenesProveedores/newOrderProvider',
                    state: { data: e },
                  });
                }
              } else {
                customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: `Intenta de nuevo`,
                  text: res?.message,
                  showCancelButton: false,
                  confirmButtonText: 'Aceptar',
                });
              }
            });
          },
        }))}
      />

      <OrdModal
        title={'Anulación'}
        subTitle={'valentina'}
        show={dataCancellation.isOpen}
        btnYesName={'Aceptar'}
        btnYesEvent={() => {
          if (dataCancellation?.item) {
            handleCancelOrders(dataCancellation?.item);
          } else {
            onCancelOrden({ data: dataCancellation?.data, fullData: dataCancellation?.fullData });
          }
        }}
        onHide={onClearDataCancellation}
        btnNoEvent={onClearDataCancellation}
      >
        <Box>
          <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
            &nbsp;Motivo
            <span className={`${styles.ordOrangeText}`}>*</span>
          </Box>

          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={listCancellationReason?.results?.map(el => ({
              label: el?.canDescription,
              value: el?.canId,
            }))}
            className='text-secondary '
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            onChange={({ value }) => {
              setDataCancellation(state => ({
                ...state,
                cancelId: value,
              }));
            }}
          />
        </Box>

        <Box my={2}>
          <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
            &nbsp;Observaciones
            <span className={`${styles.ordOrangeText}`}>*</span>
          </Box>

          <textarea
            placeholder='Escribir...'
            style={{ minHeight: '225px', maxHeight: '225px' }}
            className={`text-secondary ordInputAdmission w-100 p-2`}
            onChange={({ target }) => {
              setDataCancellation(state => ({
                ...state,
                cancelObservation: target?.value,
              }));
            }}
          />
        </Box>
      </OrdModal>
    </>
  );
};

export default OrdersPage;
