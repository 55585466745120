//react
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//styles
import styles from './styles.module.scss';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

//icons
import addIcon from '../../../assets/img/icons/add-check.svg';
import closeIcon from '../../../assets/img/icons/closeIconBigger.svg';

//hooks
import { useGetMethod, usePostMethod } from '../../../Hooks';

//utils
import moment from 'moment';
import {
  convertLocalDate,
  customSwaltAlertAsistencial,
  generateId,
  isEmptyOrUndefined,
  loader,
  validateEmail,
} from '../../../helpers';

// DatePicker
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';

//generals components
import { Box } from '@mui/material';

//locals components
import {
  SearchCardDoctor,
  SearchAppointment,
  SearchAppointmentCarousel,
  ConditionalMessage,
  RenderPdf,
} from './components';

//components
import { Form } from 'react-bootstrap';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';

const TObjectValue = { label: '', value: '' };

const defaultValueSelect = { label: 'Seleccionar...', value: '' };

const initialState = {
  showCardSchedule: false,
  date: new Date(),
  unique: generateId(),
  observation: '',
  service: TObjectValue,
  location: TObjectValue,
  site: TObjectValue,
  doctor: TObjectValue,
  listServices: [],
  listSites: [],
  listDoctors: [],
  listLocations: [],
  listSchedules: [],
  listSchedulesHours: [],
};

export const Schedule = ({
  token,
  contractId,
  corporateClientId,
  populationId,
  detailPatient,
  companyType,
  popId,
  wsValidated,
  crtName,
  crtId,
  attGroupId,
  postTicketFunction,
  setTicketValue,
  insureSelected,
  goBackFunction,
  order,
}) => {
  //======== STATES LOCALS =========//
  const store = useSelector(state => state);
  const modality = store.filterCheckTeleScheduleReducer.checked ? 'tmed' : 'on_site';
  const idAccount = store.loginReducer.currentAccount.id;
  const idUser = store.loginReducer.user_data.id;
  const fullNamePatient = `${detailPatient?.results?.firstName} ${detailPatient?.results?.otherNames} ${detailPatient?.results?.lastName} ${detailPatient?.results?.secondSurname}`;
  const bussines_name = store?.loginReducer?.currentAccount?.bussines_name;

  const [modalReminder, setModalReminder] = useState({
    appId: '',
    cluEmail: {
      inputValue: '',
      value: [],
    },
    emailSubject: 'Recordatorio de cita',
    emailBody:
      insureSelected.isMain === 0
        ? `<p>Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir! </p>`
        : `<p> Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir!</p>`,
    fileBase64: '',
    show: false,
  });
  const [enabledRequestExtraQuote, setEnabledExtraQuote] = useState(false);

  const [stateInputReminder, setStateInputReminder] = useState({
    inputValue: '',
    value: [
      { label: `${detailPatient?.results?.email}`, value: `${detailPatient?.results?.email}` },
    ],
  });

  const stateInput = {
    inputValue: '',
    value: [],
  };

  //======== STATE IMPORTANT =========//
  const [data, setData] = useState([initialState]);
  const [listHoursSelected, setListHoursSelected] = useState([]);
  const [listHoursSelectedActive, setListHoursSelectedActive] = useState([]);
  const [extraQuoteModal, setExtraQuoteModal] = useState({ open: false, reason: '' });


  //======== STATES SELECTS =========//
  const [listServices, setListServices] = useState([]);

  //========GET HTTPS=========//
  const { load: loaderListServices, trigger: getListServices } = useGetMethod();

  const { load: loaderListLocations, trigger: getListLocations } = useGetMethod();

  const { load: loaderListDoctors, trigger: getListDoctors } = useGetMethod();

  const { load: loaderScheduleDoctor, trigger: getScheduleDoctor } = useGetMethod();

  const { load: loaderTicket, trigger: getTicket } = useGetMethod();

  const { load: loaderCiteAppointment, trigger: getCiteAppointment } = useGetMethod();

  //========POST HTTPS=========//
  const { load: loaderCreateAppointment, trigger: createAppointmentMethod } = usePostMethod();

  const { load: loaderPostSmartQue, trigger: createSmartQueMethod } = usePostMethod();

  const { load: requestQuotaLoader, trigger: requestQuotaMethod } = usePostMethod();

  const { load: createReminderLoader, trigger: createReminderMethod } = usePostMethod();

  //======== EFFECTS =========//  Aquí es el
  useEffect(() => {
    getListServices({
      token,
      url: '/medical/appointment/serviceEntity/',
      objFilters: {
        popId,
        modality,
        contractId,
        companyType,
        idAccount,
        cclientId: corporateClientId,
        type: 'consultation,diagnostic_aids,procedure',
      },
    }).then(res => {
      if (res?.success) {
        const format = res?.results?.map(service => ({
          ...service,
          label: service?.srvName,
          value: service.srvId,
        }));
        const serviceValue = order.serviceId
          ? format.find(service => service?.value === order?.serviceId) ?? null
          : null;
        const newData = [
          {
            ...initialState,
            listServices: format,
            sorId: serviceValue ? order.sorId ?? '' : '',
            service: serviceValue ?? { label: '', value: '' },
          },
        ];

        if (serviceValue) {
          onChangeSelectOfService({
            serviceName: serviceValue.label,
            serviceId: serviceValue.value,
            sorId: order.sorId,
          });
        }

        setData(newData);
        setListServices(format);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modality]);

  //======== FUNCTIONS =========//

  const createOptionReminder = label => ({
    label,
    value: label,
  });

  const closeModalReminder = () => {
    setModalReminder({
      appId: '',
      cluEmail: {
        inputValue: '',
        value: [],
      },
      emailSubject: 'Recordatorio de cita',
      emailBody:
        insureSelected.isMain === 0
          ? `<p>Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir! </p>`
          : `<p> Hola, somos ${bussines_name}. Estimad@ usuario, a continuación compartimos el recordatorio de su cita agendada. Recuerda que tu visión es nuestra razón de existir!</p>`,
      fileBase64: '',
      show: false,
    });
  };

  const handleKeyDownReminder = event => {
    const { inputValue, value } = stateInputReminder;
    if (inputValue) {
      let tempArray = value;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          tempArray.push(createOptionReminder(event.target.value));
          setStateInputReminder({ inputValue: '', value: tempArray });
          break;
        default:
      }
    }
  };

  const handleInputChangeReminder = inputValue => {
    if (typeof inputValue == 'string') {
      setStateInputReminder({ ...stateInputReminder, inputValue: inputValue });
      let tempArray = [];
      stateInput.value.forEach(elem => {
        tempArray.push(elem.label);
      });
    } else if (Array.isArray(inputValue)) {
      setStateInputReminder({ ...stateInputReminder, value: inputValue });
    }
  };

  const createReminder = () => {
    let tempValues = [];
    stateInputReminder?.value.forEach(e => {
      tempValues.push(e.value);
    });
    setModalReminder({ ...modalReminder, cluEmail: tempValues });

    let validations = true;

    if (isEmptyOrUndefined(modalReminder.emailSubject)) {
      validations = false;
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: "El campo 'Asunto' es obligatorio",
        showCancelButton: false,
      });
    }

    let emailValidation;

    if (tempValues?.length > 0) {
      tempValues.forEach(e => {
        if (validateEmail(e) === true) {
          emailValidation = true;
        } else {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Valide su correo',
            showCancelButton: false,
          });
        }
      });
    } else {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: "El campo 'Para' es obligatorio",
        showCancelButton: false,
      });
    }
    let appId = [];
    appId.push(modalReminder.appId);
    if (validations && emailValidation) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se enviará el recordatorio de cita`,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const appIds = [];
          appId?.forEach(c => {
            c?.forEach(f => {
              appIds?.push(f);
            });
          });
          createReminderMethod({
            url: '/medical/appointment/paramsEmail/',
            method: 'POST',
            body: {
              appIds,
              cluEmail: tempValues,
              emailSubject: modalReminder.emailSubject,
              emailBody: modalReminder.emailBody,
              fileBase64: modalReminder.fileBase64,
            },
            token: token,
            doAfterSuccess: info => {
              closeModalReminder();
              return customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: info?.message,
                showCancelButton: false,
              });
            },
          });
        }
      });
    }
  };

  const onChangeSelectOfService = ({ serviceId, serviceName, unique, sorId }) => {
    if (serviceId === '') {
      const formatData = data?.map(el => {
        if (el?.unique === unique) {
          return {
            ...el,
            service: { label: serviceName, value: serviceId },
            site: { label: 'Seleccionar...', value: '' },
            location: { label: 'Seleccionar...', value: '' },
            doctor: { label: 'Seleccionar...', value: '' },
          };
        } else return el;
      });
      setData(formatData);
      return;
    }

    getListLocations({
      token,
      url: '/medical/appointment/locationService/',
      objFilters: { modality, idAccount, srvId: serviceId },
    }).then(res => {
      if (res?.success) {
        const formatLocations = res?.results?.map(location => ({
          ...location,
          label: location?.name,
          value: location.id,
        }));

        const formatSites = res?.results?.flatMap(location =>
          location?.sites?.flatMap(site => ({ ...site, label: site?.siteName, value: site.id })),
        );

        const formatData = data?.map(el => {
          if (el?.unique === unique) {
            return {
              ...el,
              listSites: formatSites?.length
                ? [defaultValueSelect, ...formatSites]
                : [defaultValueSelect],
              listLocations: formatLocations,
              service: { label: serviceName, value: serviceId },
              location: TObjectValue,
              site: TObjectValue,
              doctor: TObjectValue,
              showCardSchedule: false,
              sorId: sorId ?? '',
            };
          } else {
            return {
              ...el,
              sorId: sorId ?? '',
              listSites: formatSites?.length
                ? [defaultValueSelect, ...formatSites]
                : [defaultValueSelect],
              listLocations: formatLocations,
            };
          }
        });

        setData(formatData);
      }
    });
  };

  const onChangeSelectOfLocation = ({ locationId, locationName, unique }) => {
    if (locationId === '') {
      const formatData = data?.map(el => {
        if (el?.unique === unique) {
          return {
            ...el,
            location: { label: locationName, value: locationId },
            doctor: { label: 'Seleccionar...', value: '' },
            site: { label: 'Seleccionar...', value: '' },
          };
        }
        return el;
      });
      setData(formatData);
      return;
    }

    const serviceId = data?.find(el => el?.unique === unique)?.service?.value;

    getListDoctors({
      token,
      url: '/medical/appointment/medical-by-contract-and-location/',
      objFilters: {
        idAccount,
        popId,
        contractId,
        srvId: serviceId,
        city: locationId,
      },
    }).then(res => {
      if (res?.success) {
        const formatDoctors = res?.results?.map(doctor => ({
          ...doctor,
          label: doctor?.mdName,
          value: doctor.mdId,
        }));

        const formatData = data?.map(el => {
          if (el?.unique === unique) {
            return {
              ...el,
              listDoctors: formatDoctors?.length
                ? [defaultValueSelect, ...formatDoctors]
                : [defaultValueSelect],
              location: { label: locationName, value: locationId },
              site: TObjectValue,
              doctor: TObjectValue,
              showCardSchedule: false,
            };
          }

          return el;
        });

        setData(formatData);
      }
    });
  };

  const onChangeSelectOfSite = ({ siteId, siteName, unique }) => {
    const formatSite = { label: siteName, value: siteId };

    const formatData = data?.map(el => {
      if (el?.unique === unique) {
        return {
          ...el,
          site: formatSite,
          doctor: TObjectValue,
          showCardSchedule: false,
        };
      }

      return el;
    });

    setData(formatData);
  };

  const onChangeSelectOfDoctor = ({ doctorId, doctorName, unique }) => {
    const formatDoctor = { label: doctorName, value: doctorId };

    const formatData = data?.map(el => {
      if (el?.unique === unique) {
        return { ...el, doctor: formatDoctor, showCardSchedule: false };
      }

      return el;
    });

    setData(formatData);
  };

  const onSearchSchedules = ({ unique }) => {
    //hacer la validación de los campos obligatorios para que pueda buscar las agendas
    /////////////////////////////////////////////
    setEnabledExtraQuote(false);
    const filters = data?.find(el => el?.unique === unique);

    getScheduleDoctor({
      token,
      url: '/medical/appointment/availability/',
      objFilters: {
        modality,
        eaccount: idAccount,
        attentionGroup: attGroupId,
        idContractcorporateClient: contractId,
        idCorporateClient: corporateClientId,
        idPopulation: populationId,
        idService: filters?.service?.value,
        city: filters?.location?.value,
        idDoctor: filters?.doctor?.value,
        site: filters?.site?.value,
        days: 3,
      },
      doAfterException: res => {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res?.message,
          showCancelButton: false,
        });
      },
    }).then(res => {
      if (res?.success) {
        if (!res?.results?.length) {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'No hay agendas disponibles',
            showCancelButton: false,
          }).finally(() => {
            onHideCardSchedule({ unique });
          });
        }

        const listSchedules = res?.results?.map(available => ({
          ...available,
          schedule: available?.schedule?.map(sch => ({
            ...sch,
            hours: sch?.hours?.map(hour => ({
              ...hour,
              virtualAppointment: hour?.appointment,
            })),
          })),
        }));

        const formatData = data?.map(el => {
          el.date = res?.results[0]?.date;
          if (el?.unique === unique) {
            return {
              ...el,
              listSchedules,
              showCardSchedule: true,
              proximateDates: res?.proximateDates?.map(el => moment(el)?.toDate()) ?? [],
            };
          }

          return el;
        });

        setData(formatData);
      }
    });
  };
  const onToggleDoctor = ({ unique, active, idSchedule, schedules }) => {
    if (!active) {
      const formatListHoursSelectedActive = listHoursSelectedActive?.map(el => {
        if (el?.unique === unique) {
          return {
            ...el,
            active: false,
          };
        }
        return el;
      });

      const formatData = data?.map(el => {
        const newListSchedulesHours = el?.listSchedulesHours?.map?.(sch => {
          return {
            ...sch,
            hours: sch?.hours?.map(hour => {
              if (
                formatListHoursSelectedActive?.filter(
                  el => el?.hourStart === hour?.hourStart && hour?.date === el?.date && !el?.active,
                )?.length
              ) {
                return {
                  ...hour,
                  appointment: false,
                  selected: false,
                };
              }

              return {
                ...hour,
                locked: hour?.appointment,
              };
            }),
          };
        });

        return {
          ...el,
          listSchedules:
            el?.unique === unique
              ? el?.listSchedules?.map(sch =>
                  sch?.idSchedule === idSchedule ? { ...sch, active } : sch,
                )
              : el?.listSchedules,
          listSchedulesHours: newListSchedulesHours,
        };
      });

      setData(formatData);
      setListHoursSelectedActive(formatListHoursSelectedActive);
    } else {
      const formatListHoursSelectedActive = listHoursSelectedActive?.filter(
        el => el?.unique !== unique,
      );

      const formatData = data?.map(el => {
        const newListSchedulesHours = [];

        el?.listSchedules?.forEach?.(item => {
          item?.schedule?.forEach(sch => {
            if (sch?.idSchedule === idSchedule) {
              newListSchedulesHours.push({
                ...sch,
                hours: sch?.hours?.map(hour => {
                  if (
                    formatListHoursSelectedActive?.filter(
                      el =>
                        el?.hourStart === hour?.hourStart && hour?.date === el?.date && el?.active,
                    )?.length
                  ) {
                    return {
                      ...hour,
                      selected: Boolean(
                        formatListHoursSelectedActive?.filter(
                          element => element?.unique === el?.unique,
                        )?.length,
                      ),
                      appointment: true,
                    };
                  }

                  return {
                    ...hour,
                    locked: hour?.appointment,
                  };
                }),
              });
            }
          });
        });

        return {
          ...el,
          listSchedules:
            el?.unique === unique
              ? el?.listSchedules?.map(sch =>
                  sch?.idSchedule === idSchedule ? { ...sch, active } : { ...sch, active: false },
                )
              : el?.listSchedules,

          listSchedulesHours:
            el?.unique === unique
              ? newListSchedulesHours?.length
                ? newListSchedulesHours
                : schedules
              : el?.listSchedulesHours,
        };
      });

      setData(formatData);
      setListHoursSelectedActive(formatListHoursSelectedActive);
    }
  };

  //por verificar
  const onToggleHourSchedule = ({ unique, date, idSchedule, hourStart, hourEnd }) => {
    const hasHours = listHoursSelected?.filter(
      el => el?.date === date && el?.hourStart === hourStart,
    )?.length;

    const formatHours = hasHours
      ? listHoursSelected
      : listHoursSelected?.length
        ? [...listHoursSelected, { date, hourStart, hourEnd, idSchedule, unique }]
        : [{ date, hourStart, hourEnd, idSchedule, unique, active: true }];

    const hasHoursActives = listHoursSelectedActive?.filter(el => el?.unique === unique)?.length;

    let newActive = [];

    if (!hasHoursActives) {
      newActive = listHoursSelectedActive?.length
        ? [
            ...listHoursSelectedActive,
            { date, hourStart, hourEnd, idSchedule, unique, active: true },
          ]
        : [{ date, hourStart, hourEnd, idSchedule, unique, active: true }];

      setListHoursSelectedActive(newActive);
    }

    if (hasHoursActives) {
      newActive = listHoursSelectedActive?.map(el => {
        if (el?.unique === unique) {
          return {
            date,
            hourStart,
            hourEnd,
            idSchedule,
            unique,
            active: true,
          };
        }
        return el;
      });

      setListHoursSelectedActive(newActive);
    }

    const formatData = data?.map(el => {
      if (el?.unique === unique) {
        return {
          ...el,
          listSchedulesHours: el?.listSchedulesHours?.map(sch => {
            return {
              ...sch,
              hours: sch?.hours?.map(hour => {
                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === date &&
                      item?.unique === unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: true,
                  };
                }

                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === hour?.date &&
                      item?.unique !== unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.hourStart !== hour?.hourStart && item?.date === date,
                  )?.length
                ) {
                  return {
                    ...hour,
                    systemAppointment: hour?.appointment,
                    appointment: hour?.virtualAppointment ? true : false,
                    selected: false,
                    blockByUser: false,
                  };
                }

                return {
                  ...hour,
                  selected: false,
                };
              }),
            };
          }),
        };
      }

      if (moment(el?.date)?.format('YYYY-MM-DD') === date && el?.unique !== unique) {
        return {
          ...el,
          listSchedulesHours: el?.listSchedulesHours?.map(sch => {
            return {
              ...sch,
              hours: sch?.hours?.map(hour => {
                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === date &&
                      item?.unique === unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item =>
                      item?.date === date &&
                      item?.hourStart === hour?.hourStart &&
                      item?.unique === el?.unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: true,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.date === date && item?.hourStart === hour?.hourStart,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.hourStart !== hour?.hourStart && item?.date === date,
                  )?.length
                ) {
                  return {
                    ...hour,
                    systemAppointment: hour?.appointment,
                    appointment: hour?.virtualAppointment ? true : false,
                    selected: false,
                    blockByUser: false,
                  };
                }

                return hour;
              }),
            };
          }),
        };
      }

      return el;
    });

    setData(formatData);

    setListHoursSelected(formatHours);
  };

  const onChangeDatePicker = ({ unique, newDate, filters, requestExtraQuote }) => {
    getScheduleDoctor({
      token,
      url: '/medical/appointment/availability/',
      objFilters: {
        modality,
        eaccount: idAccount,
        attentionGroup: attGroupId,
        idContractcorporateClient: contractId,
        idCorporateClient: corporateClientId,
        idPopulation: populationId,
        idService: filters?.service?.value,
        additionalQuotaMode: requestExtraQuote,
        city: filters?.location?.value,
        idDoctor: filters?.doctor?.value,
        site: filters?.site?.value,
        dateSearch: moment(newDate).format('YYYY-MM-DD'),
        days: 3,
      },
      doAfterException: res => {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res?.message,
          showCancelButton: false,
        });
      },
    }).then(res => {
      if (res?.success) {
        const listSchedules = res?.results?.map(available => ({
          ...available,
          schedule: available?.schedule?.map(sch => ({
            ...sch,
            hours: sch?.hours?.map(hour => ({
              ...hour,
              virtualAppointment: hour?.appointment,
            })),
          })),
        }));

        const formatData = data?.map(el => {
          if (el?.unique === unique) {
            return {
              ...el,
              listSchedules,
              date: moment(newDate).format('YYYY-MM-DD'),
              showCardSchedule: true,
            };
          }

          return el;
        });
        setData(formatData);

        if (!res?.results?.length) {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'No hay agendas disponibles',
            showCancelButton: false,
          });
        }
      }
    });
  };

  const validateForm = () => {
    const hasService = data?.every(el => !isEmptyOrUndefined(el?.service.value));
    const hasLocation = data?.every(el => !isEmptyOrUndefined(el?.location.value));
    const hasSchedule = data?.every(el => el?.listSchedules?.some?.(sch => sch?.active));
    let hours = [];

    data?.forEach(el => {
      el?.listSchedulesHours?.forEach(sch => {
        sch?.hours?.forEach(hour => {
          if (hour?.selected) hours?.push({ ...hour, unique: el?.unique });
        });
      });
    });

    const hasScheduleHour = !hasSchedule ? false : data?.length > hours.length ? false : true;

    return {
      hours,
      hasService,
      hasSchedule,
      hasLocation,
      hasScheduleHour,
      isValid: hasService && hasLocation && hasSchedule && hasScheduleHour,
    };
  };

  const onCreateAppointment = () => {
    const { isValid, hasLocation, hasSchedule, hasScheduleHour, hasService, hours } =
      validateForm();

    if (!isValid) {
      if (!hasService) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El campo servicio es obligatorio',
          showCancelButton: false,
        });
      }

      if (!hasLocation) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El campo ubicación es obligatorio',
          showCancelButton: false,
        });
      }

      if (!hasSchedule) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'La agenda es obligatoria',
          showCancelButton: false,
        });
      }

      if (!hasScheduleHour) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione una hora valida',
          showCancelButton: false,
        });
      }
    }

    const body = data?.map(el => {
      return {
        sorId: el.sorId ?? '',
        affiliationTypeId: detailPatient?.results?.affiliationTypeId,
        modality,
        idPatient: detailPatient?.results?.id,
        createdBy: idUser,
        idCorporateClient: corporateClientId,
        idPopulation: popId,
        rv: wsValidated === 0 ? 'False' : 'True',
        idCompanyType: companyType,
        eaccount: idAccount,
        origin: 'on_site',
        status: 'pending_payment',
        idSch: el?.listSchedules?.find(item => item?.active)?.idSchedule,
        idDoctor: el?.listSchedules?.find(item => item?.active)?.idDoctor,
        idDoctorContract: el?.listSchedules?.find(item => item?.active)?.idDoctorContract,
        idContractCorporateClient: el?.listSchedules?.find(item => item?.active)
          ?.idContractCorporateClient,
        idService: el?.service?.value,
        appObservations: el?.observation,
        category: crtName,
        appDatetime: `${moment(el?.date)?.format('YYYY-MM-DD')} ${hours?.find(hour => hour?.unique === el?.unique)?.hourStart}`,
        appEndDatetime: `${moment(el?.date)?.format('YYYY-MM-DD')} ${hours?.find(hour => hour?.unique === el?.unique)?.hourEnd}`,
      };
    });

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text:
        data?.length === 1
          ? `Se agendará una cita al paciente ${fullNamePatient} el día ${hours[0]?.date} a las ${moment(`${hours[0]?.date} ${hours[0]?.hourStart}`)?.format('h:mm a')}`
          : `Se agendarán (${data?.length}) citas para el paciente ${fullNamePatient}`,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        createAppointmentMethod({
          url: '/medical/appointment/createAppointments/',
          method: 'POST',
          body: { apps: body },
          token: token,
          doAfterSuccess: info => {
            postTicketFunction('appointment_registration', info?.results?.appIds);

            getTicket({
              url: '/medical/appointment/concecutive/',
              objFilters: { idConsec: 23 },
              token: token,
              doAfterSuccess: res => {
                setTicketValue(res.results);
              },
            });
          },
          succesAction: info => {
            getCiteAppointment({
              url: '/medical/appointment/',
              objFilters: { appId: info?.results?.appIds[0] },
              token: token,
              doAfterSuccess: data => {
                if (
                  data?.results?.ctName?.toLowerCase() === 'Prepagada'.toLowerCase() &&
                  data?.results?.modality?.toLowerCase() === 'tmed'.toLowerCase()
                ) {
                  createSmartQueMethod({
                    url: '/medical/appointment/smartQue/',
                    method: 'POST',
                    body: { idApp: info?.results?.appIds[0] },
                    noAlert: true,
                    token: token,
                  });
                }
              },
            });

            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Cita registrada',
              text: info.message,
              confirmButtonText: 'Ver Recordatorio',
            })
              .then(result => {
                if (result.isConfirmed) {
                  setModalReminder({ ...modalReminder, show: true, appId: info?.results?.appIds });
                }
              })
              .finally(() => {
                setData([initialState]);
              });
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const sendRequestQuota = () => {
    const { isValid, hasLocation, hasSchedule, hasScheduleHour, hasService } = validateForm();

    if (!isValid) {
      if (!hasService) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El campo servicio es obligatorio',
          showCancelButton: false,
        });
      }

      if (!hasLocation) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El campo ubicación es obligatorio',
          showCancelButton: false,
        });
      }

      if (!hasSchedule) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'La agenda es obligatoria',
          showCancelButton: false,
        });
      }

      if (!hasScheduleHour) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Seleccione una hora valida',
          showCancelButton: false,
        });
      }
    }

    if (!extraQuoteModal.reason) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de llenar el motivo.',
        showCancelButton: false,
      });
    }

    const schedule = data?.[0];
    const hours = listHoursSelectedActive?.[0];

    requestQuotaMethod({
      url: '/medical/appointment/requestExtraQuota/',
      method: 'POST',
      token: token,
      body: {
        cluId: detailPatient?.results?.id,
        srvId: schedule.service.value,
        popId: populationId,
        appDateTime: `${schedule.date} ${hours.hourStart}`,
        appEndDatetime: `${schedule.date} ${hours.hourEnd}`,
        schId: hours.idSchedule,
        doctorId: schedule.doctor.value || schedule.listSchedules[0]?.idDoctor,
        cclientId: corporateClientId,
        contractId: contractId,
        userId: idUser,
        crtId: crtId,
        crtName: crtName,
        comments: extraQuoteModal.reason,
      },
      succesAction: info => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Cupo adicional solicitado',
          text: info.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        }).then(result => {
          if (result.isConfirmed) {
            setExtraQuoteModal({ open: false, reason: '' });
            setData([initialState]);
            setEnabledExtraQuote(false);
          }
        });
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  const onAddNewAppointment = () => {
    setData(state => [...state, { ...initialState, unique: generateId() }]);
  };

  const onDeleteAppointment = ({ unique }) => {
    const formatListHoursSelectedActive = listHoursSelectedActive?.filter(
      el => el?.unique === unique,
    );

    const formatData = data
      ?.filter(el => el.unique !== unique)
      ?.map(el => {
        const newListSchedulesHours = el?.listSchedulesHours?.map?.(sch => {
          return {
            ...sch,
            hours: sch?.hours?.map(hour => {
              if (
                formatListHoursSelectedActive?.filter(
                  el => el?.hourStart === hour?.hourStart && hour?.date === el?.date,
                )?.length
              ) {
                return {
                  ...hour,
                  appointment: false,
                  selected: false,
                };
              }

              return hour;
            }),
          };
        });

        return {
          ...el,
          listSchedulesHours: newListSchedulesHours,
        };
      });

    setListHoursSelectedActive(listHoursSelectedActive?.filter(el => el.unique !== unique));
    setData(formatData);
  };

  const onHideCardSchedule = ({ unique }) => {
    const formatData = data?.map(el => {
      if (el?.unique === unique) {
        return { ...el, listSchedules: [], listSchedulesHours: [], showCardSchedule: false };
      }

      return el;
    });

    setData(formatData);
  };

  return (
    <div style={{ overflow: 'auto' }}>
      {(loaderListLocations ||
        loaderListServices ||
        loaderListDoctors ||
        loaderCreateAppointment ||
        loaderTicket ||
        loaderCiteAppointment ||
        loaderPostSmartQue ||
        createReminderLoader ||
        requestQuotaLoader ||
        loaderScheduleDoctor) &&
        loader}

      <div style={data.length > 1 ? { height: '55vh', overflow: 'auto' } : {}}>
        {data?.map(item => (
          <Box
            key={item?.unique}
            style={{ padding: '24px 24px 28px 24px', position: 'relative', marginTop: '20px' }}
            className={`${styles.app_container_calendar}`}
          >
            <img
              alt='close'
              src={closeIcon}
              className={`${tableStyles.filterOrdAquaMarine} ${tableStyles.stylesCloseCiteSchedule}`}
              onClick={() => onDeleteAppointment({ unique: item?.unique })}
            />

            {/*component filters appointment */}
            <SearchAppointment
              data={data}
              listSites={item?.listSites}
              listDoctors={item?.listDoctors}
              listServices={listServices}
              listLocations={item?.listLocations}
              service={item?.service}
              location={item?.location}
              site={item?.site}
              doctor={item?.doctor}
              order={order}
              onSearchSchedules={() => onSearchSchedules({ unique: item?.unique })}
              onChangeSelectOfService={({ serviceName, serviceId, sorId }) => {
                onChangeSelectOfService({ serviceId, serviceName, sorId, unique: item?.unique });
              }}
              onChangeSelectOfLocation={({ locationName, locationId }) => {
                onChangeSelectOfLocation({ locationName, locationId, unique: item?.unique });
              }}
              onChangeSelectOfSite={({ siteName, siteId }) => {
                onChangeSelectOfSite({ siteName, siteId, unique: item?.unique });
              }}
              onChangeSelectOfDoctor={({ doctorName, doctorId }) => {
                onChangeSelectOfDoctor({ doctorName, doctorId, unique: item?.unique });
              }}
            />

            {/* este es el contenido principal */}

            {item?.showCardSchedule ? (
              <>
                {/* componente de linea */}
                <Box mt={2} className={`${styles.app_line_search}`} />

                <Box
                  mt={2}
                  display={'grid'}
                  gridTemplateColumns={'243px 1fr 180px'}
                  alignItems={'flex-start'}
                  columnGap={3}
                >
                  {/* componente del calendario*/}

                  <div>
                    <div id='new-style-react-date'>
                      <DatePicker
                        inline
                        key={1}
                        locale={es}
                        minDate={new Date()}
                        selected={convertLocalDate(item?.date)}
                        onChange={newDate => {
                          onChangeDatePicker({ newDate, unique: item?.unique, filters: item });
                          setEnabledExtraQuote(false);
                        }}
                        highlightDates={item?.proximateDates}
                      />
                    </div>
                    <button
                      className={`${tableStyles.button_assign_appointment} ${enabledRequestExtraQuote ? tableStyles.inverse : ''}`}
                      onClick={() => {
                        const newValue = !enabledRequestExtraQuote;
                        setEnabledExtraQuote(newValue);
                        onChangeDatePicker({
                          newDate: item.date,
                          unique: item?.unique,
                          filters: item,
                          requestExtraQuote: newValue ? 1 : undefined,
                        });
                      }}
                      disabled={
                        !item?.listSchedules?.find(sch => sch.date === item.date)
                          ?.allowsExtraQuotas ||
                        item?.listSchedules?.find(sch => sch.date === item.date)
                          ?.availableSpaces !== 0
                      }
                    >
                      {enabledRequestExtraQuote ? 'Cancelar cupo adicional' : 'Solicitar cupo adicional'}
                    </button>
                  </div>

                  {/*componente de carousel con los horarios disponibles y sino mostrar mensaje que no hay disponibles*/}

                  {item?.listSchedules?.filter(doctor => doctor?.active)?.length ? (
                    <SearchAppointmentCarousel
                      currentDate={item.date}
                      schedules={item?.listSchedulesHours}
                      onToggleHourSchedule={({ idSchedule, hourStart, hourEnd, date, time }) => {
                        onToggleHourSchedule({
                          date,
                          time,
                          hourStart,
                          hourEnd,
                          idSchedule,
                          unique: item?.unique,
                        });
                      }}
                    />
                  ) : (
                    <ConditionalMessage key={2} hasSchedules={item?.listSchedules?.length} />
                  )}

                  <div style={{ height: '250px', overflowY: 'auto' }}>
                    {item?.listSchedules?.map(schedule => (
                      <SearchCardDoctor
                        active={schedule?.active}
                        key={schedule?.idSchedule}
                        scheduleName={schedule?.scheduleName}
                        doctorName={schedule?.doctor}
                        doctorImage={schedule?.image}
                        onToggleDoctor={() => {
                          onToggleDoctor({
                            unique: item.unique,
                            active: !schedule?.active,
                            idSchedule: schedule?.idSchedule,
                            schedules: schedule?.schedule,
                          });
                        }}
                      />
                    ))}
                  </div>
                </Box>
              </>
            ) : (
              ''
            )}

            {item?.showCardSchedule ? (
              <div className='my-2'>
                <Form.Group className='mb-3' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className={` px-1`}>Anotaciones</b>
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={2}
                    className='text-secondary'
                    type='text'
                    placeholder='Escribe...'
                    onChange={({ target }) => {
                      const formatData = data?.map(el => {
                        if (el?.unique === item?.unique) {
                          return { ...el, observation: target.value };
                        }
                        return el;
                      });

                      setData(formatData);
                    }}
                  />
                </Form.Group>
              </div>
            ) : (
              ''
            )}
          </Box>
        ))}

        {!extraQuoteModal?.open && (
          <div
            className={`${tableStyles.appButtonHover} ms-1 d-inline-flex pointer`}
            style={{ marginTop: '15px' }}
            onClick={onAddNewAppointment}
          >
            <img alt='addIcon' src={addIcon} className={`${tableStyles.AppIconHoverTurquoise}`} />
            <div
              className={`${tableStyles.tlnTextGray} ${tableStyles.f14} ${tableStyles.appTextHoverTurquoise} mx-2 fw-bold`}
              style={{ marginTop: '2px' }}
            >
              Añadir servicio
            </div>
          </div>
        )}
      </div>

      {data?.length ? (
        <div className='my-4'>
          <div className='d-flex gap-2 justify-content-end align-items-end align-self-end mb-2'>
            <button
              onClick={goBackFunction}
              className={`${tableStyles.ordBtnSecondary} p-2`}
              style={{ height: '35px' }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                enabledRequestExtraQuote
                  ? setExtraQuoteModal({ open: true, reason: '' })
                  : onCreateAppointment();
              }}
              className={`${tableStyles.ordBtnPrimary} p-2`}
              style={{ height: '35px' }}
            >
              {enabledRequestExtraQuote ? 'Solicitar' : 'Registrar'}
            </button>
          </div>
        </div>
      ) : (
        ''
      )}

      <OrdModal
        title='Motivo'
        show={extraQuoteModal.open}
        btnYesName='Aceptar'
        btnNoName='Cancelar'
        size='200'
        onHide={() => setExtraQuoteModal({ open: false, reason: '' })}
        btnYesEvent={() => sendRequestQuota()}
        btnNoEvent={() => setExtraQuoteModal({ open: false, reason: '' })}
      >
        <Form.Group className=' text-start' controlId=''>
          <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              &nbsp;Motivo <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
            </span>
          </Form.Label>
          <textarea
            placeholder={'Escribir...'}
            style={{ height: '5rem' }}
            className={`text-secondary ord-roundInput ord-roundInputActive w-100`}
            value={extraQuoteModal.reason}
            onChange={({ target }) => {
              setExtraQuoteModal(state => ({ ...state, reason: target.value }));
            }}
          />
        </Form.Group>
      </OrdModal>

      <RenderPdf
        isOpenPdf={modalReminder?.show}
        onHidePdf={closeModalReminder}
        appId={String(modalReminder?.appId)}
        setModalReminder={setModalReminder}
        closeModalReminder={closeModalReminder}
        stateInputReminder={stateInputReminder}
        handleInputChangeReminder={handleInputChangeReminder}
        handleKeyDownReminder={handleKeyDownReminder}
        modalReminder={modalReminder}
        createReminder={createReminder}
      />
    </div>
  );
};
