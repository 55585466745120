import { Form } from 'react-bootstrap';
import moment from 'moment';
import ReactSelect from 'react-select';

import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod, usePostMethod } from '../../../Hooks';
import { customSwaltAlertAsistencial, loader } from '../../../helpers';
import { formatNumberWithoutDecimals } from '../../../helpers/numberFormatting';

import ordBlueSearch from '../../../assets/img/icons/ordBlueSearch.svg';

import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';

import { ordCustomSelect, selectMultiple } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

const header = [
  { title: 'Fecha hora', className: 'px-2' },
  { title: 'Factura', className: 'px-2 text-start' },
  { title: 'Paciente', className: 'px-2 text-start' },
  { title: 'Contrato', className: 'px-2 text-center' },
  { title: 'Servicio', className: 'px-2 text-center' },
  { title: 'Monto', className: 'px-2 text-end' },
];

export default function InCash() {
  const token = useSelector(store => store.loginReducer.Authorization);
  const userId = useSelector(store => store.loginReducer.user_data.id);
  const accountId = useSelector(store => store.loginReducer.currentAccount.id);

  const formRef = useRef(null);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [doctor, setDoctor] = useState(null);

  const [payload, setPayload] = useState({
    initialDate: '',
    finalDate: '',
  });
  const [servicesList, setServicesList] = useState([]);

  const { trigger: getPendingRemunerations, load: pendingRemunerationsLoader } = useGetMethod();
  const { trigger: getDoctors, results: doctorsResult, load: doctorsLoader } = useGetMethod();
  const {
    trigger: getServiceTypes,
    results: serviceTypesResult,
    load: serviceTypesLoader,
  } = useGetMethod();
  const { trigger: createReport, load: createReportLoader } = usePostMethod();

  useEffect(() => {
    void getDoctors({
      url: '/medical/generals/doctors/',
      token: token,
      objFilters: { idAccount: accountId },
    });

    void getServiceTypes({
      url: '/medical/generals/serviceType/',
      objFilters: { listAll: 1 },
      token: token,
    });
  }, [getDoctors, getServiceTypes, token, accountId]);

  const doctorsList = useMemo(() => {
    if (doctorsResult?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...doctorsResult.results.map(doc => ({
          label: doc.nameDoctor,
          value: doc.idDoctor,
        })),
      ];
    }
    return [];
  }, [doctorsResult?.results]);

  const serviceTypesList = useMemo(() => {
    if (serviceTypesResult?.results) {
      return serviceTypesResult.results.map(doc => ({
        label: doc.serviceType,
        value: doc.serviceTypeId,
      }));
    }
    return [];
  }, [serviceTypesResult?.results]);

  const onCreateReport = () => {
    void createReport({
      url: `/medical/doctors/${doctor.value}/remuneration-reports`,
      method: 'POST',
      token: token,
      body: {
        userId: userId,
        initialDate: payload.initialDate,
        finalDate: payload.finalDate,
        type: 'in_cash',
        serviceTypes: serviceTypes.map(s => s.value).join(','),
      },
      succesAction: (results) => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'success',
          title: 'Liquidación generada exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
        setServicesList([]);
        formRef.current?.reset();
        setServiceTypes([]);
        setDoctor(null);
        setPayload({
          initialDate: '',
          finalDate: '',
          doctor: null,
        });
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
        setServicesList([]);
        formRef.current?.reset();
        setServiceTypes([]);
        setDoctor(null);
        setPayload({
          initialDate: '',
          finalDate: '',
        });
      },
    });
  };

  const onSubmitForm = event => {
    event.preventDefault();

    const {
      dateFrom: { value: dateFromValue },
      dateTo: { value: dateToValue },
    } = event.target;

    if (!dateFromValue.length || !dateToValue.length || !doctor?.value) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de llenar los filtros obligatorios.',
        showCancelButton: false,
      });
      return;
    }
    setPayload({
      initialDate: dateFromValue,
      finalDate: dateToValue,
    });

    void getPendingRemunerations({
      url: `/medical/doctors/${doctor?.value}/pending-remunerations`,
      token: token,
      objFilters: {
        userId: userId,
        initialDate: dateFromValue,
        finalDate: dateToValue,
        serviceTypes: serviceTypes.map(s => s.value).join(','),
        type: 'in_cash',
      },
      succesAction: results => {
        if (results?.results && !results.results.length) {
          void customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'No hay servicios disponibles para liquidar.',
            showCancelButton: false,
          });
          return;
        }
        setServicesList(results.results);
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
        setServicesList([]);
      },
    });
  };

  const renderComponent = () => {
    return (
      <>
        {doctorsLoader ||
          serviceTypesLoader ||
          pendingRemunerationsLoader ||
          (createReportLoader && loader)}
        <form ref={formRef} onSubmit={onSubmitForm} className='row d-flex align-items-end'>
          <div className='col'>
            <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>
              Fecha desde<span className='text-danger'>*</span>
            </div>
            <Form.Control
              type='date'
              className='ord-roundInput'
              style={{ width: '100%' }}
              name='dateFrom'
              placeholder='Escribe...'
            />
          </div>
          <div className='col'>
            <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>
              Fecha hasta<span className='text-danger'>*</span>
            </div>
            <Form.Control
              type='date'
              className='ord-roundInput'
              style={{ width: '100%' }}
              name='dateTo'
              max={new Date().toISOString('en-US').split('T')[0]}
              placeholder='Escribe...'
            />
          </div>
          <div className='col'>
            <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>
              Médico<span className='text-danger'>*</span>
            </div>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary'
              styles={ordCustomSelect}
              options={doctorsList}
              value={doctor}
              onChange={option => setDoctor(option)}
              name='medic'
              placeholder={'Seleccionar...'}
            />
          </div>
          <div className='col'>
            <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>Tipos de servicio</div>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={serviceTypesList}
              isMulti
              isSearchable
              styles={selectMultiple}
              value={serviceTypes}
              onChange={option => setServiceTypes(option)}
              placeholder={'Seleccionar...'}
              hideSelectedValues={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
            />
          </div>
          <div className='col-lg-1'>
            <button type='submit' className='mb-2'>
              <img src={ordBlueSearch} width={16} alt='' />
            </button>
          </div>
        </form>

        {servicesList.length > 0 && (
          <>
            {servicesList.map(serviceType => (
              <Fragment key={serviceType.id}>
                <h5 className={`fw-bold ${tableStyles?.ordDarkBlueText} mt-4`}>
                  {serviceType.name}
                </h5>
                <OrdTable
                  shadow
                  headers={header}
                  hasChildren={true}
                  oneHeadDarkBlue={false}
                  lowerCase={false}
                >
                  {serviceType.services.map(service => (
                    <tr key={service.id}>
                      <td align='left' className='px-2'>
                        {moment(service.attentionDateTime).format('DD-MM-YYYY HH:MM')}
                      </td>
                      <td align='left' className='px-2'>
                        {service.resolution}
                      </td>
                      <td align='left' className='px-2'>
                        {service.patient.name}
                      </td>
                      <td align='center' className='px-2'>
                        {service.contract.name}
                      </td>
                      <td align='center' className='px-2'>
                        {service.service.name}
                      </td>
                      <td align='right' className='px-2'>
                        {formatNumberWithoutDecimals(service.total)}
                      </td>
                    </tr>
                  ))}
                </OrdTable>
              </Fragment>
            ))}
            <div className='d-flex justify-content-end mt-3 mb-4'>
              <button
                className={`${tableStyles.tlnBtnPrimary} btn`}
                onClick={() => onCreateReport()}
              >
                Generar
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  return renderComponent();
}
