import { Drawer } from '@mui/material';
import moment from 'moment';
import React, { Children } from 'react';
import { Calendar } from 'react-big-calendar';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import userLogo from '../../assets/img/header/user-img.png';
import Reloj from '../../assets/img/icons/Reloj.svg';
import block from '../../assets/img/icons/block.svg';
import calendar from '../../assets/img/icons/calendar.svg';
import closeIcon from '../../assets/img/icons/close.svg';
import estetoscopic from '../../assets/img/icons/estetoscopic.svg';
import locations from '../../assets/img/icons/location.svg';
import person from '../../assets/img/icons/person.svg';
import user from '../../assets/img/icons/user.svg';
import usuario from '../../assets/img/icons/usuario.svg';

import useCalendarBlockTextarea from '../../Hooks/useCalendarBlockTextarea';
import useCalendarStore from '../../Hooks/useCalendarStore';
import formatHours from '../../helpers/formatHours';
import { loader, message } from '../../helpers/helpers';
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { getMessagesES, localizer } from './../../helpers/';

import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import BlockAgenda from './BlockAgenda';
import CalendarEvent from './CalendarEvent';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from '../../components/Layouts/tableStyle.module.scss';

const CalendarPage = ({
  objDoctor = '',
  objSite = '',
  optionalDoctor,
  views,
  container,
  calendarOption,
  schedule,
  filterScheduleChange,
  setFilterScheduleChange,
  isEditingSchedule,
  setIsEditingSchedule,
  setTab,
  tab,
}) => {
  const MySwal = withReactContent(Swal);

  const store = useSelector(state => state);

  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const idUser = store.loginReducer.user_data.id;

  const [currentDate, setCurrentDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'));

  const [optionBlock, setOptionBlock] = React.useState(false);

  const [drawerListblockMonthData, setDrawerListblockMonthData] = React.useState(false);

  const [selectedOption, setSelectedOption] = React.useState('month');

  const [selectEventActive, setSelectEventActive] = React.useState(null);

  const [data, setData] = React.useState({
    modalActive: false,
    event: [],
  });

  const [modalBlockActive, setModalBlockActive] = React.useState(false);

  const { activeEvent, events, setActiveEvent, setEvents } = useCalendarStore();

  const { comment, setBlockTextarea } = useCalendarBlockTextarea();

  const [filterBlock, setFilterBlock] = React.useState({
    method: '',
    idAgenda: '',
    date: '',
    idUser: '',
    comment: '',
    thisDay: false,
    thisDayAndAll: false,
  });
  const [optionalActiveEvent, setOptionalActiveEvent] = React.useState({});
  const [dateGrid, setDateGrid] = React.useState('second');
  const [newSchId, setNewSchId] = React.useState(undefined);

  const {
    results: listEventsDay,
    trigger: getListEventsDay,
    load: loadListEventsDay,
  } = useGetMethod();

  const {
    results: ListEventBlockReprogrammingMonth,
    trigger: getListEventBlockReprogrammingMonth,
    load: loadListEventBlockReprogrammingMonth,
  } = useGetMethod();

  const {
    results: listEventsSite,
    trigger: getListEventsSite,
    load: loadListEventsSite,
  } = useGetMethod();

  const {
    results: listEventsWeek,
    trigger: getListEventsWeek,
    load: loadListEventsWeek,
  } = useGetMethod();

  const {
    results: listEventsMonth,
    trigger: getListEventsMonth,
    load: loadListEventsMonth,
  } = useGetMethod();

  const { trigger: sendBlockMonth, load: loadListBlockMonth } = usePostMethod();

  const { trigger: sendUnlockMonth, load: loadListUnlockMonth } = usePostMethod();

  const eventStyledGetter = () => {
    const style = {
      borderRadius: '0px',
      opacity: 0.8,
    };

    return { style };
  };

  const onSelect = event => {
    setNewSchId(event);

    if (event !== null) {
      setSelectEventActive({ ...event });
      setActiveEvent(event);
      setOptionalActiveEvent(event);
      setDateGrid(event);
    }

    if (selectedOption === 'month') {
      setData({ event, modalActive: true });
    }

    if (selectedOption === 'day' || selectedOption === 'week') {
      setData({ event, modalActive: true });
    }
  };

  const onViewChange = event => {
    calendarOption?.setCalendarOptionDate(event);

    setSelectedOption(event);

    setFilterScheduleChange('');
  };

  const DateCellWrapper = ({ children }) => {
    if (objDoctor.mdId) {
      let cellStyle = React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: 'white',
        },
      });
      return cellStyle;
    }

    if (objSite.idSite) {
      let cellStyle = React.cloneElement(Children.only(children), {
        style: {
          ...children.style,
        },
      });
      return cellStyle;
    }
  };

  const onNavigate = e => {
    setCurrentDate(moment(e).format('YYYY-MM-DD'));
  };

  const eventWrapperComponent = ({ event, children }) => {
    const newChildren = { ...children };
    const newChildrenProps = { ...newChildren.props };
    newChildrenProps.className = `${newChildrenProps.className} 
        outline-none border-none  `;

    if (selectedOption === 'week') {
      newChildrenProps.style = {
        ...newChildrenProps.style,
        borderRight: `3px solid ${event.color}`,
        borderLeft: `3px solid ${event.color}`,
        borderTop: 'transparent',
        borderBottom: 'transparent',
        color: `${event.color}`,
        borderRadius: '0px',
        padding: '10px',
        background: `${event.color}80`,
        fontSize: '10px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
        justifyContent: 'center',
        fontWeight: 'bold',
      };
    }

    if (selectedOption === 'month') {
      if (objSite.idSite) {
        newChildrenProps.style = {
          ...newChildrenProps.style,
          borderRight: `transparent`,
          borderLeft: `transparent`,
          borderTop: 'transparent',
          borderBottom: 'transparent',
          color: `${event.color}`,
          padding: '0px',
          background: `${event.enabled === 0 ? '#d6e0eb' : event.color + 80}`,
          cursor: 'pointer',
          // background: 'black',
          borderRadius: '11px',
          fontSize: '10px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '2px',
          justifyContent: 'center',
          fontWeight: 'bold',
        };
      }

      if (objDoctor.mdId) {
        newChildrenProps.style = {
          ...newChildrenProps.style,
          borderRight: `transparent`,
          borderLeft: `transparent`,
          borderTop: 'transparent',
          borderBottom: 'transparent',
          padding: '0px',
          color: `${event.enabled === 0 ? '#77787a' : `${event.color}`}`,
          cursor: 'pointer',
          background: `${event.enabled === 0 ? '#d6e0eb' : `${event.color + 80}`}`,
          borderRadius: '11px',
          fontSize: '10px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '2px',
          justifyContent: 'center',
          fontWeight: 'bold',
        };
      }
    }

    if (selectedOption === 'day') {
      newChildrenProps.style = {
        ...newChildrenProps.style,
        borderRight: '0px',
        borderLeft: '0px',
        borderTop: 'transparent',
        padding: '0px',
        background: `${event.color}80`,
        // background: 'black',
        borderRadius: '0px',
        minHeight: '10px !important',
      };
    }

    newChildren.props = { ...newChildrenProps };

    return <div>{newChildren}</div>;
  };

  React.useEffect(() => {
    const btnGroup = document.querySelector('.rbc-btn-group');
    const rbcToolbarLabel = document.querySelector('.rbc-toolbar-label');
    btnGroup?.children[0].remove();
    btnGroup?.prepend(rbcToolbarLabel);

    if (selectedOption === 'week' && objDoctor.mdId) {
      calendarOption?.setCalendarOptionDate('week');
      getListEventsWeek({
        url: `/medical/schedules`,
        objFilters: {
          view: 'month',
          doctorId: objDoctor?.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });
    }

    if (selectedOption === 'month' && objDoctor.mdId) {
      calendarOption?.setCalendarOptionDate('month');

      getListEventsMonth({
        url: `/medical/schedules`,
        objFilters: {
          view: 'month',
          doctorId: objDoctor?.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });
    }

    if (selectedOption === 'day' && objDoctor.mdId) {
      getListEventsDay({
        url: `/medical/schedules`,
        objFilters: {
          view: 'day',
          doctorId: objDoctor?.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objDoctor.mdId]);

  React.useEffect(() => {
    const btnGroup = document.querySelector('.rbc-btn-group');
    const rbcToolbarLabel = document.querySelector('.rbc-toolbar-label');
    btnGroup?.children[0].remove();
    btnGroup?.prepend(rbcToolbarLabel);

    if (selectedOption === 'month' && objDoctor.mdName) {
      getListEventsMonth({
        url: `/medical/schedules`,
        objFilters: {
          view: 'month',
          doctorId: objDoctor.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });

      const data =
        listEventsMonth?.results?.map(e => ({
          ...e,
          beginsAt: new Date(e.beginsAt),
          endsAt: new Date(e.endsAt),
        })) || [];

      if (data?.length) {
        let filters = [];
        data.forEach((e, i) => {
          if (i === 0) {
            filters.push(e);
          } else {
            if (filters.filter(x => x.name === e.name).length === 0) {
              filters.push(e);
            }
          }
        });
        schedule.setFilterSchedule(filters);

        return setEvents(data);
      } else {
        schedule.setFilterSchedule([]);

        return setEvents([]);
      }
    }

    if (selectedOption === 'week' && objDoctor.mdId) {
      getListEventsWeek({
        url: `/medical/schedules`,
        objFilters: {
          view: 'month',
          doctorId: objDoctor.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });

      const data =
        listEventsWeek?.results?.map(e => ({
          ...e,
          beginsAt: new Date(e.beginsAt),
          endsAt: new Date(e.endsAt),
        })) || [];

      if (data?.length) {
        let filters = [];
        data.forEach((e, i) => {
          if (i === 0) {
            filters.push(e);
          } else {
            if (filters.filter(x => x.name === e.name).length === 0) {
              filters.push(e);
            }
          }
        });

        schedule.setFilterSchedule(filters);

        return setEvents(data);
      } else {
        schedule.setFilterSchedule([]);

        return setEvents([]);
      }
    }

    if (selectedOption === 'day' && objDoctor.mdId) {
      getListEventsDay({
        url: `/medical/schedules`,
        objFilters: {
          view: 'day',
          doctorId: objDoctor.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });

      const data =
        listEventsDay?.results?.map(e => ({
          ...e,
          beginsAt: new Date(e.beginsAt),
          endsAt: new Date(e.endsAt),
        })) || [];

      if (data?.length) {
        return setEvents(data);
      } else {
        return setEvents([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  React.useEffect(() => {
    const btnGroup = document.querySelector('.rbc-btn-group');
    const rbcToolbarLabel = document.querySelector('.rbc-toolbar-label');
    btnGroup?.children[0].remove();
    btnGroup?.prepend(rbcToolbarLabel);

    if (objDoctor?.mdId === '' && objSite.idSite === '') {
      setSelectedOption('month');
      calendarOption?.setCalendarOptionDate('month');
    }

    if (selectedOption !== '' && objSite.idSite) {
      calendarOption?.setCalendarOptionDate('month');
      setSelectedOption('month');
      getListEventsSite({
        url: `/medical/schedules`,
        objFilters: { view: 'site', site: objSite.idSite, date: currentDate, eaccount: idAccount },
        token: token,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objSite.idSite]);

  React.useEffect(() => {
    if (selectedOption === 'day' && objDoctor.mdId) {
      getListEventsDay({
        url: `/medical/schedules`,
        objFilters: {
          view: 'day',
          doctorId: objDoctor.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });
    }

    if (selectedOption === 'week' && objDoctor.mdId) {
      getListEventsWeek({
        url: `/medical/schedules`,
        objFilters: {
          view: 'month',
          doctorId: objDoctor.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });
    }

    if (selectedOption === 'month' && objSite?.idSite === '') {
      getListEventsMonth({
        url: `/medical/schedules`,
        objFilters: {
          view: 'month',
          doctorId: objDoctor.mdId,
          date: currentDate,
          eaccount: idAccount,
        },
        token: token,
      });
    }

    if (selectedOption === 'month' && objSite?.idSite) {
      getListEventsSite({
        url: `/medical/schedules`,
        objFilters: { view: 'site', site: objSite.idSite, date: currentDate, eaccount: idAccount },
        token: token,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  React.useEffect(() => {
    if (selectedOption === 'day' && objDoctor.mdId) {
      if (listEventsDay.results?.length) {
        const data = [];

        listEventsDay.results.forEach(e => {
          data.push(new Date(e.beginsAt), new Date(e.endsAt));
        });
      }

      const data =
        listEventsDay?.results?.map(e => ({
          ...e,
          beginsAt: new Date(e.beginsAt),
          endsAt: new Date(e.endsAt),
        })) || [];

      if (data?.length) {
        return setEvents(data);
      } else {
        return setEvents([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEventsDay]);

  React.useEffect(() => {
    if (selectedOption !== '' && !objDoctor.mdId) {
      if (listEventsSite.results?.length) {
        const data = [];

        listEventsSite.results.forEach(e => {
          data.push(new Date(e.beginsAt), new Date(e.endsAt));
        });
      }

      const data =
        listEventsSite?.results?.map(e => ({
          ...e,
          beginsAt: new Date(e.beginsAt),
          endsAt: new Date(e.endsAt),
        })) || [];

      if (data?.length) {
        let filters = [];
        data.forEach((e, i) => {
          if (i === 0) {
            filters.push(e);
          } else {
            if (filters.filter(x => x.name === e.name).length === 0) {
              filters.push(e);
            }
          }
        });

        schedule.setFilterSchedule(filters);

        return setEvents(data);
      } else {
        schedule.setFilterSchedule([]);

        return setEvents([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEventsSite]);

  React.useEffect(() => {
    if (selectedOption === 'week' && objDoctor.mdId) {
      if (listEventsWeek?.results?.length) {
        const data = [];

        listEventsWeek?.results.forEach(e => {
          data.push(new Date(e.beginsAt), new Date(e.endsAt));
        });
      }

      const data =
        listEventsWeek?.results?.map(e => ({
          ...e,
          beginsAt: new Date(e.beginsAt),
          endsAt: new Date(e.endsAt),
        })) || [];

      if (data?.length) {
        let filters = [];
        data.forEach((e, i) => {
          if (i === 0) {
            filters.push(e);
          } else {
            if (filters.filter(x => x.name === e.name).length === 0) {
              filters.push(e);
            }
          }
        });
        schedule.setFilterSchedule(filters);

        return setEvents(data);
      } else {
        schedule.setFilterSchedule([]);

        return setEvents([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEventsWeek]);

  React.useEffect(() => {
    if (selectedOption === 'month' && objDoctor.mdId) {
      if (listEventsMonth?.results?.length) {
        const data = [];

        listEventsMonth?.results.forEach(e => {
          data.push(new Date(e.beginsAt), new Date(e.endsAt));
        });
      }
      const data =
        listEventsMonth?.results?.map(e => ({
          ...e,
          beginsAt: new Date(e.beginsAt),
          endsAt: new Date(e.endsAt),
        })) || [];

      if (data?.length) {
        let filters = [];
        data.forEach((e, i) => {
          if (i === 0) {
            filters.push(e);
          } else {
            if (filters.filter(x => x.name === e.name).length === 0) {
              filters.push(e);
            }
          }
        });
        schedule.setFilterSchedule(filters);

        return setEvents(data);
      } else {
        schedule.setFilterSchedule([]);

        return setEvents([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEventsMonth]);

  React.useEffect(() => {
    if (selectedOption === 'month' && objDoctor.mdId) {
      if (listEventsMonth?.results?.length) {
        const data = [];

        listEventsMonth?.results.forEach(e => {
          data.push(new Date(e.beginsAt), new Date(e.endsAt));
        });
      }

      const dataPush = [];
      listEventsMonth?.results?.forEach(e => {
        if (filterScheduleChange === '') {
          dataPush.push({
            ...e,
            beginsAt: new Date(e.beginsAt),
            endsAt: new Date(e.endsAt),
          });
        } else {
          if (e.name === filterScheduleChange) {
            dataPush.push({
              ...e,
              beginsAt: new Date(e.beginsAt),
              endsAt: new Date(e.endsAt),
            });
          }
        }
      });

      if (dataPush?.length) {
        return setEvents(dataPush);
      } else {
        return setEvents([]);
      }
    }

    if (selectedOption === 'month' && objSite.idSite) {
      if (listEventsSite?.results?.length) {
        const data = [];

        listEventsSite?.results.forEach(e => {
          data.push(new Date(e.beginsAt), new Date(e.endsAt));
        });
      }

      const dataPush = [];
      listEventsSite?.results?.forEach(e => {
        if (filterScheduleChange === '') {
          dataPush.push({
            ...e,
            beginsAt: new Date(e.beginsAt),
            endsAt: new Date(e.endsAt),
          });
        } else {
          if (e.name === filterScheduleChange) {
            dataPush.push({
              ...e,
              beginsAt: new Date(e.beginsAt),
              endsAt: new Date(e.endsAt),
            });
          }
        }
      });
      if (dataPush?.length) {
        return setEvents(dataPush);
      } else {
        return setEvents([]);
      }
    }

    if (selectedOption === 'week' && objDoctor.mdId) {
      if (listEventsWeek?.results?.length) {
        const data = [];

        listEventsWeek?.results.forEach(e => {
          data.push(new Date(e.beginsAt), new Date(e.endsAt));
        });
      }
      const dataPush = [];
      listEventsWeek?.results?.forEach(e => {
        if (filterScheduleChange === '') {
          dataPush.push({
            ...e,
            beginsAt: new Date(e.beginsAt),
            endsAt: new Date(e.endsAt),
          });
        } else {
          if (e.name === filterScheduleChange) {
            dataPush.push({
              ...e,
              beginsAt: new Date(e.beginsAt),
              endsAt: new Date(e.endsAt),
            });
          }
        }
      });

      if (dataPush?.length) {
        return setEvents(dataPush);
      } else {
        return setEvents([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterScheduleChange]);

  React.useEffect(() => {
    if (isEditingSchedule?.isEditing) {
      setFilterScheduleChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingSchedule?.isEditing]);

  React.useEffect(() => {
    setEvents([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objDoctor.mdId, objSite.idSite]);

  const handleSubmitBlock = async () => {
    if (activeEvent?.enabled) {
      if (comment.trim() === '' || filterBlock.method.trim() === '') {
        setModalBlockActive(true);
        return MySwal.fire({
          icon: 'warning',
          text: `Por favor digite todos los campos`,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Si, Continuar',
          cancelButtonColor: '#d33',
          showCancelButton: false,
        });
      }
    } else {
      if (filterBlock.method.trim() === '') {
        setModalBlockActive(true);
        return MySwal.fire({
          icon: 'warning',
          text: `Por favor seleccione una opción`,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Si, Continuar',
          cancelButtonColor: '#d33',
          showCancelButton: false,
        });
      }
    }

    try {
      MySwal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: activeEvent?.enabled
          ? 'La agenda será bloqueada para detener las programaciones, sin embargo, estas que ya existían en la agenda seguirán activas, recuerde reagendarlas.'
          : 'La agenda será desbloqueada!',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          setFilterBlock({ comment: '', method: '', thisDay: false, thisDayAndAll: false });
          setBlockTextarea('');

          if (activeEvent?.enabled) {
            sendBlockMonth({
              url: `/medical/schedules/block/`,
              token: token,
              noAlert: true,
              method: 'POST',

              body: objSite?.idSite
                ? {
                    method: filterBlock.method,
                    idSite: objSite.idSite,
                    date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format(
                      'YYYY-MM-DD',
                    ),
                    idUser: idUser,
                    comment: comment,
                  }
                : objDoctor?.mdName
                  ? {
                      method: filterBlock.method,
                      idAgenda: data.event.id || optionalActiveEvent.id,
                      date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format(
                        'YYYY-MM-DD',
                      ),
                      idUser: idUser,
                      comment: comment,
                    }
                  : {},

              doAfterSuccess: response => {
                if (response.status !== 200) {
                  if (objSite?.idSite) {
                    getListEventsSite({
                      url: `/medical/schedules`,
                      objFilters: {
                        view: 'site',
                        site: objSite.idSite,
                        date: currentDate,
                        eaccount: idAccount,
                      },
                      token: token,
                    });
                  }

                  if (objDoctor?.mdName) {
                    getListEventsMonth({
                      url: `/medical/schedules`,
                      objFilters: {
                        view: 'month',
                        doctorId: objDoctor?.mdId,
                        date: currentDate,
                        eaccount: idAccount,
                      },
                      token: token,
                    });
                  }

                  MySwal.fire({
                    icon: 'success',
                    title:
                      objSite.idSite && activeEvent?.enabled
                        ? 'Agenda(s) bloqueada(s)'
                        : objSite.idSite && !activeEvent?.enabled
                          ? 'Agenda(s) desbloqueada(s)'
                          : !objSite.idSite && !activeEvent?.enabled
                            ? 'Día desbloqueado'
                            : 'Día bloqueado',
                    text: objDoctor.mdName && response.message,
                    confirmButtonColor: '#00B4CC',
                    confirmButtonText: 'Si, Continuar',
                    showCancelButton: false,
                  }).then(rs => {
                    if (rs.isConfirmed) {
                      if (objSite?.idSite) {
                        const data1 = !optionBlock
                          ? {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                site: objSite.idSite,
                              },
                              token: token,
                            }
                          : {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                site: objSite.idSite,
                                blockedDay: moment(
                                  data.event.beginsAt || optionalActiveEvent.beginsAt,
                                ).format('YYYY-MM-DD'),
                              },
                              token: token,
                            };

                        getListEventBlockReprogrammingMonth(data1);
                      }

                      if (objDoctor?.mdName) {
                        const data1 = !optionBlock
                          ? {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                idSchedule: data.event.id || optionalActiveEvent.id,
                              },
                              token: token,
                            }
                          : {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                idSchedule: data.event.id || optionalActiveEvent.id,
                                blockedDay: moment(
                                  data.event.beginsAt || optionalActiveEvent.beginsAt,
                                ).format('YYYY-MM-DD'),
                              },
                              token: token,
                            };

                        getListEventBlockReprogrammingMonth(data1);
                      }
                      setDrawerListblockMonthData(true);
                    } else {
                      setDrawerListblockMonthData(false);
                    }
                  });
                }
              },
            });
          } else {
            sendUnlockMonth({
              url: `/medical/schedules/unblock/`,
              token: token,
              noAlert: true,
              method: 'POST',

              body: objSite?.idSite
                ? {
                    method: filterBlock.method,
                    idSite: objSite.idSite,
                    date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format(
                      'YYYY-MM-DD',
                    ),
                    idUser: idUser,
                  }
                : objDoctor?.mdName
                  ? {
                      method: filterBlock.method,
                      idAgenda: data.event.id || optionalActiveEvent.id,
                      date: moment(data.event.beginsAt || optionalActiveEvent.beginsAt).format(
                        'YYYY-MM-DD',
                      ),
                    }
                  : {},

              doAfterSuccess: response => {
                if (response.status !== 200) {
                  if (objSite?.idSite) {
                    getListEventsSite({
                      url: `/medical/schedules`,
                      objFilters: {
                        view: 'site',
                        site: objSite.idSite,
                        date: currentDate,
                        eaccount: idAccount,
                      },
                      token: token,
                    });
                  }

                  if (objDoctor?.mdName) {
                    getListEventsMonth({
                      url: `/medical/schedules`,
                      objFilters: {
                        view: 'month',
                        doctorId: objDoctor?.mdId,
                        date: currentDate,
                        eaccount: idAccount,
                      },
                      token: token,
                    });
                  }

                  MySwal.fire({
                    icon: 'success',
                    title:
                      objSite.idSite && activeEvent?.enabled
                        ? 'Agenda(s) bloqueada(s)'
                        : objSite.idSite && !activeEvent?.enabled
                          ? 'Agenda(s) desbloqueada(s)'
                          : !objSite.idSite && !activeEvent?.enabled
                            ? 'Día desbloqueado'
                            : 'Día bloqueado',
                    text: objDoctor.mdName && response.message,
                    confirmButtonColor: '#00B4CC',
                    confirmButtonText: 'Si, Continuar',
                    showCancelButton: false,
                  }).then(rs => {
                    if (rs.isConfirmed) {
                      if (objSite?.idSite) {
                        const data1 = !optionBlock
                          ? {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                site: objSite.idSite,
                              },
                              token: token,
                            }
                          : {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                site: objSite.idSite,
                                blockedDay: moment(
                                  data.event.beginsAt || optionalActiveEvent.beginsAt,
                                ).format('YYYY-MM-DD'),
                              },
                              token: token,
                            };

                        getListEventBlockReprogrammingMonth(data1);
                      }

                      if (objDoctor?.mdName) {
                        const data1 = !optionBlock
                          ? {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                idSchedule: data.event.id || optionalActiveEvent.id,
                              },
                              token: token,
                            }
                          : {
                              url: `/medical/generals/appointments/`,
                              objFilters: {
                                sidSchedule: data.event.id || optionalActiveEvent.id,
                                blockedDay: moment(
                                  data.event.beginsAt || optionalActiveEvent.beginsAt,
                                ).format('YYYY-MM-DD'),
                              },
                              token: token,
                            };

                        getListEventBlockReprogrammingMonth(data1);
                      }
                      setDrawerListblockMonthData(true);
                    } else {
                      setDrawerListblockMonthData(false);
                    }
                  });
                }
              },
            });
          }
        } else {
          setFilterBlock({ comment: '', method: '', thisDay: false, thisDayAndAll: false });
          setBlockTextarea('');
        }
      });
    } catch (error) {
      return message('error', 'Error', error.message);
    }
  };

  const getScheduleIdToEdit = () => {
    setIsEditingSchedule({
      isEditing: true,
      schId: activeEvent?.id || newSchId?.id,
    });

    setData(state => ({ ...state, modalActive: false }));

    setTab({ ...tab, page: 'newSchedule' });
  };

  return (
    <>
      {/* ANCHOR CONFIG MODAL  */}
      <OrdModal
        titleClassName={'app_titleModalCalendar'}
        headerModalClassName={'headerModalClassName'}
        footerModalClassName={'footerModalClassName'}
        headerBorderNone={'headerBorderNone'}
        styleBody={'app_body'}
        iconBlock={{
          active: selectedOption === 'month' ? true : false,
          isBlock: data.event.enabled === 0 ? true : false,
        }}
        title={
          selectedOption === 'day'
            ? activeEvent?.serviceName
            : selectedOption === 'month' && objSite.idSite === ''
              ? activeEvent?.name || optionalActiveEvent?.name
              : selectedOption === 'week' && objSite.idSite === ''
                ? activeEvent?.consultingRoomName
                : selectedOption === 'month' && objSite.idSite !== ''
                  ? objSite?.site
                  : ''
        }
        show={data.modalActive}
        size={'200'}
        onHide={() => {
          setData(state => ({ ...state, modalActive: false }));
          setFilterBlock({ comment: '', method: '', thisDay: false, thisDayAndAll: false });
          setBlockTextarea('');
        }}
        onBlock={() => {
          setData(state => ({ ...state, modalActive: false }));
          setModalBlockActive(true);
          setFilterBlock({ comment: '', method: '', thisDay: false, thisDayAndAll: false });
          setBlockTextarea('');
        }}
        onEdit={getScheduleIdToEdit}
        hideCancelButton
        showEditButton={objSite.idSite === ''}
      >
        {selectEventActive !== null && selectedOption === 'month' && objSite.idSite === '' && (
          <>
            <p className='boxModal2'>
              {data.event?.description || optionalActiveEvent?.description}
            </p>

            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={calendar}
                  alt=''
                  style={{ height: '15px' }}
                />
                <span>Cada semana el {data.event?.dowValue || optionalActiveEvent?.dowValue}</span>
              </div>

              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={locations}
                  style={{ height: '15px' }}
                  alt=''
                />
                <span>
                  {activeEvent?.siteDescription || optionalActiveEvent?.siteDescription} -{' '}
                  {activeEvent?.consultingRoomName || optionalActiveEvent?.consultingRoomName}{' '}
                  {activeEvent?.site || optionalActiveEvent?.site}
                </span>
              </div>

              <div className='boxModal'>
                <img src={Reloj} style={{ height: '15px' }} alt='' />
                <span>
                  {formatHours(activeEvent?.beginsAt || optionalActiveEvent?.beginsAt)}{' '}
                  {formatHours(activeEvent?.endsAt || optionalActiveEvent?.endsAt)} (
                  {Number(
                    formatHours(activeEvent?.endsAt || optionalActiveEvent?.endsAt).split(':')[0],
                  ) -
                    Number(
                      formatHours(activeEvent?.beginsAt || optionalActiveEvent?.beginsAt).split(
                        ':',
                      )[0],
                    )}{' '}
                  hora(s))
                </span>
              </div>

              {data.event.enabled === 0 && (
                <div className='boxModal' style={{ marginLeft: '-2px' }}>
                  <img
                    className={`${styles.svgBlueFilter}`}
                    src={block}
                    style={{ height: '18px' }}
                    alt=''
                  />
                  <span>
                    {data.event?.blockReason ||
                      optionalActiveEvent?.blockReason ||
                      'motivo de bloqueo, no disponible'}
                  </span>
                </div>
              )}
              {(activeEvent?.auxName !== null || optionalActiveEvent?.auxName !== null) && (
                <div className='boxModal'>
                  <img src={person} style={{ height: '15px' }} alt='' />
                  <span>Asistente - {activeEvent?.auxName || optionalActiveEvent?.auxName}</span>
                </div>
              )}

              <div>
                <div className='container d-flex flex-column text-start align-self-center w-100'>
                  <label
                    htmlFor='inputTypeColor'
                    className={`${styles.appInputColorExa} ${styles.ordDarkBlueText} ${styles.f14} mb-1`}
                  >
                    Color
                  </label>
                  <input
                    type='color'
                    id='inputTypeColor'
                    value={`${data.event?.color || optionalActiveEvent?.color}`}
                    disabled
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {selectEventActive !== null && selectedOption === 'month' && objSite.idSite !== '' && (
          <>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={calendar}
                  style={{ height: '15px' }}
                  alt=''
                />
                <span>{moment(activeEvent?.beginsAt).format('LL')}</span>
              </div>

              <div className='boxModal'>
                <img src={Reloj} style={{ height: '15px' }} alt='' />
                <span>
                  {formatHours(activeEvent?.beginsAt)} {formatHours(activeEvent?.endsAt)}
                </span>
              </div>

              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={estetoscopic}
                  style={{ height: '15px' }}
                  alt=''
                />
                <span>{activeEvent?.appointmentsCount} Citas agendadas</span>
              </div>

              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={usuario}
                  style={{ height: '15px' }}
                  alt=''
                />
                <span>
                  {activeEvent?.doctorsCount} {activeEvent?.doctorsCount > 1 ? 'médicos' : 'médico'}
                </span>
              </div>

              {activeEvent?.enabled === 0 && (
                <div className='boxModal' style={{ marginLeft: '-2px' }}>
                  <img
                    className={`${styles.svgBlueFilter}`}
                    src={block}
                    style={{ height: '18px' }}
                    alt=''
                  />
                  <span>
                    {activeEvent?.blockReason ||
                      optionalActiveEvent?.blockReason ||
                      'motivo de bloqueo, no disponible'}
                  </span>
                </div>
              )}
            </div>
          </>
        )}

        {selectEventActive !== null && selectedOption === 'week' && (
          <>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={locations}
                  style={{ height: '15px' }}
                  alt=''
                />
                <span>{`${activeEvent?.siteDescription}`}</span>
              </div>

              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={locations}
                  style={{ height: '15px' }}
                  alt=''
                />
                <span>
                  {activeEvent?.site}, {activeEvent?.consultingRoomName}
                </span>
              </div>

              <div className='boxModal'>
                <img src={Reloj} style={{ height: '15px' }} alt='' />
                <span>
                  {moment(activeEvent?.beginsAt).format('h:mm a')} -{' '}
                  {moment(activeEvent?.endsAt).format('h:mm a')}
                </span>
              </div>
            </div>
          </>
        )}
        {selectEventActive !== null && selectedOption === 'day' && (
          <>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <div className='boxModal'>
                <img src={user} style={{ height: '15px' }} alt='' />
                <span>{`${activeEvent?.patientName} ${activeEvent?.patientSecondName} ${activeEvent?.patientSecondSurname} ${activeEvent?.patientLastName}`}</span>
              </div>

              <div className='boxModal'>
                <img
                  className={`${styles.svgBlueFilter}`}
                  src={locations}
                  style={{ height: '15px' }}
                  alt=''
                />
                <span>
                  {activeEvent?.site}, {activeEvent?.consultingRoomName}
                </span>
              </div>

              <div className='boxModal'>
                <img src={Reloj} style={{ height: '15px' }} alt='' />
                <span>{activeEvent?.serviceTime} minutos</span>
              </div>
            </div>
          </>
        )}
      </OrdModal>

      <OrdModal
        headerBorderNone={'headerBorderNone'}
        title={data.event?.enabled === 0 ? 'Desbloquear' : 'Bloquear'}
        show={modalBlockActive}
        size={400}
        onHide={() => setModalBlockActive(false)}
        btnYesName='Guardar'
        btnYesEvent={() => {
          setModalBlockActive(false);
          handleSubmitBlock();
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
          <div className='boxModal'>
            <img
              className={`${styles.svgBlueFilter}`}
              src={calendar}
              style={{ height: '15px' }}
              alt=''
            />
            <span>Cada semana el {data.event?.dowValue}</span>
          </div>

          <div className='boxModal'>
            <img src={Reloj} style={{ height: '15px' }} alt='' />
            <span>
              {formatHours(data.event?.beginsAt)} {formatHours(data.event?.endsAt)}
            </span>
          </div>

          {data.event?.enabled ? <BlockAgenda /> : ''}

          <div className='mb-2'>
            {data.event && !data.event.enabled && (
              <>
                {!data.event.enabled | !data.event.enabled &&
                data.event.blockBySchedule | optionalActiveEvent.blockBySchedule ? (
                  <div style={{ color: '#6E6F7C' }}>
                    <input
                      className='form-check-input styleCheckInputRatio mr-2 cursorPointer'
                      style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                      onChange={() => {
                        setFilterBlock(state => ({
                          ...state,
                          method: 'followingDays',
                          thisDayAndAll: true,
                          thisDay: false,
                        }));

                        setOptionBlock(false);
                      }}
                      checked={filterBlock.thisDayAndAll}
                      type='radio'
                      id='checkReqAuth'
                    ></input>
                    <span>Este dia y los siguientes*</span>
                  </div>
                ) : (
                  ''
                )}

                {!data.event.enabled | !optionalActiveEvent.enabled &&
                !data.event.blockBySchedule | !optionalActiveEvent.blockBySchedule ? (
                  <div style={{ color: '#6E6F7C' }}>
                    <input
                      className='form-check-input styleCheckInputRatio mr-2 cursorPointer'
                      style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                      onChange={() => {
                        setFilterBlock(state => ({
                          ...state,
                          method: 'thisDay',
                          thisDayAndAll: false,
                          thisDay: true,
                        }));

                        setOptionBlock(true);
                      }}
                      checked={filterBlock.thisDay}
                      type='radio'
                      id='checkReqAuth'
                    ></input>
                    <span>Este dia*</span>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}

            {data.event && data.event.enabled ? (
              <>
                <div style={{ color: '#6E6F7C' }}>
                  <input
                    className='form-check-input styleCheckInputRatio mr-2 cursorPointer'
                    style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                    onChange={() => {
                      setFilterBlock(state => ({
                        ...state,
                        method: 'followingDays',
                        thisDayAndAll: true,
                        thisDay: false,
                      }));

                      setOptionBlock(false);
                    }}
                    checked={filterBlock.thisDayAndAll}
                    type='radio'
                    id='checkReqAuth'
                  ></input>
                  <span>Este dia y los siguientes*</span>
                </div>

                <div style={{ color: '#6E6F7C' }}>
                  <input
                    className='form-check-input styleCheckInputRatio mr-2 cursorPointer'
                    style={{ border: '2px solid #1a538d', color: '#6E6F7C' }}
                    onChange={() => {
                      setFilterBlock(state => ({
                        ...state,
                        method: 'thisDay',
                        thisDayAndAll: false,
                        thisDay: true,
                      }));

                      setOptionBlock(true);
                    }}
                    checked={filterBlock.thisDay}
                    type='radio'
                    id='checkReqAuth'
                  ></input>
                  <span>Este dia*</span>
                </div>

                {/* } */}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </OrdModal>

      <Drawer anchor={'bottom'} open={drawerListblockMonthData} style={{ zIndex: 900 }}>
        <div className={styles.app_drawer3}>
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <div className={styles.app_container_close} style={{ display: 'flex' }}>
              <div className={styles.xx}>
                <div style={{ color: '#00b4cc', fontWeight: 'bold', fontSize: '30px' }}>
                  <span>Reprogramación de citas</span>
                </div>
                {optionBlock && (
                  <div style={{ color: '#1a538d', fontWeight: 'bold' }}>
                    <span>{moment(dateGrid?.beginsAt).format('LL')}</span>
                  </div>
                )}
              </div>
              <div>
                <img
                  src={closeIcon}
                  width={25}
                  className={'cursorPointer'}
                  alt='closeIcon'
                  onClick={() => {
                    setDrawerListblockMonthData(false);
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '50px' }}>
            {typeof ListEventBlockReprogrammingMonth?.results !== 'undefined' &&
            ListEventBlockReprogrammingMonth.results.length ? (
              <table
                style={{
                  width: '80%',
                  margin: 'auto auto 100px auto',
                  boxShadow: '0px 0px 0px 1px #00b4cc3d',
                  borderRadius: '10px',
                }}
              >
                <div
                  style={{
                    height: 'auto',
                    fontSize: '13px',
                    fontFamily: 'PT Sans Caption',
                    padding: '3px',
                    overflowY: 'scrol',
                    overflowX: 'hidden',
                  }}
                >
                  <tr
                    style={{
                      color: '#00b4cc',
                      fontWeight: 'bold',
                      background: '#f5fcfd',
                      display: 'inline-table',
                      width: '100%',
                    }}
                  >
                    <th style={{ width: '15%' }}>Fecha Atención</th>
                    <th style={{ width: '20%' }}>Paciente</th>
                    <th style={{ width: '20%' }}>Correo electrónico</th>
                    <th style={{ width: '15%' }}>WhatsApp</th>
                    <th style={{ width: '10%' }}>Atención</th>
                    <th style={{ width: '10%' }}>Aseguradora</th>
                    <th style={{ width: '10%' }}>Estado</th>
                  </tr>
                  {ListEventBlockReprogrammingMonth?.results.map((e, i) =>
                    i === ListEventBlockReprogrammingMonth?.results.length - 1 ? (
                      <tr key={i} className={styles.styleT2}>
                        <td style={{ width: '200px', color: '#696969' }}>
                          <div>{e.dateAttentionApp.split(' ')[0]}</div>
                          <div>
                            {e.dateAttentionApp.split(' ')[1]}
                            <span> {e.dateAttentionApp.split(' ')[2]}</span>
                          </div>
                        </td>
                        <td style={{ color: '#696969' }}>
                          <div>{e.fullNamePatient}</div>
                          <div>{e.documentDoctor}</div>
                        </td>
                        <td style={{ color: '#696969' }}>{e.emailPatient}</td>
                        <td style={{ color: '#696969' }}>
                          <span>{`+57 ${e.whatsappPatient}`}</span>
                        </td>
                        <td style={{ color: '#696969' }}>{e.modalityLabel}</td>
                        <td style={{ color: '#696969' }}>{e.company}</td>
                        <td style={{ color: '#696969' }}>{e.statusApp}</td>
                      </tr>
                    ) : (
                      <tr key={i} className={styles.styleTr}>
                        <td style={{ width: '200px', color: '#696969' }}>
                          <div>{e.dateAttentionApp.split(' ')[0]}</div>
                          <div>
                            {e.dateAttentionApp.split(' ')[1]}
                            <span> {e.dateAttentionApp.split(' ')[2]}</span>
                          </div>
                        </td>
                        <td style={{ color: '#696969' }}>
                          <div>{e.fullNamePatient}</div>
                          <div>{e.documentDoctor}</div>
                        </td>
                        <td style={{ color: '#696969' }}>{e.emailPatient}</td>
                        <td style={{ color: '#696969' }}>
                          <span>{`+57 ${e.whatsappPatient}`}</span>
                        </td>
                        <td style={{ color: '#696969' }}>{e.modalityLabel}</td>
                        <td style={{ color: '#696969' }}>{e.company}</td>
                        <td style={{ color: '#696969' }}>{e.statusApp}</td>
                      </tr>
                    ),
                  )}
                </div>
              </table>
            ) : (
              <>
                <div className='alert alert-primary w-70 text-center m-auto' role='alert'>
                  No hay citas disponibles
                </div>
              </>
            )}
          </div>
        </div>
      </Drawer>

      <div className={container ? '' : styles.calendarContainer}>
        {(loadListEventsDay ||
          loadListUnlockMonth ||
          loadListEventsWeek ||
          loadListEventsSite ||
          loadListEventsMonth ||
          loadListEventBlockReprogrammingMonth ||
          loadListBlockMonth) &&
          loader}
        {objDoctor?.mdName !== '' && objDoctor !== '' && (
          <>
            <div className={styles.headerCalendarDoctor}>
              <div
                style={{
                  boxShadow: '0px 0px 3',
                  backgroundImage: `url(${objDoctor.photo !== '' ? objDoctor.photo : userLogo} )`,
                  backgroundSize: 'contain',
                  height: '50px',
                  width: '50px',
                  borderRadius: '100%',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>

              <div className={styles.headerCalendarDoctor_nameDoctor}>
                {`Dr. ${objDoctor?.mdName}`}
              </div>
            </div>

            <div>
              <Calendar
                showAllEvents={true}
                culture='es'
                localizer={localizer}
                defaultView={'month'}
                events={events}
                startAccessor='beginsAt'
                endAccessor='endsAt'
                style={{ height: '70vh' }}
                messages={getMessagesES()}
                eventPropGetter={eventStyledGetter}
                components={{
                  eventWrapper: eventWrapperComponent,
                  event: e => CalendarEvent(e, selectedOption, objSite, objDoctor),
                  dateCellWrapper: DateCellWrapper,
                }}
                onSelectEvent={onSelect}
                onView={onViewChange}
                views={{
                  month: true,
                  week: true,
                  day: true,
                }}
                onNavigate={onNavigate}
              />
            </div>
          </>
        )}

        {objSite?.site !== '' && objSite !== '' && (
          <>
            <div className={styles.headerCalendarSite}>
              <div className={styles.headerCalendarDoctor_nameDoctor}>
                {`Agenda ${objSite?.site}`}
              </div>
            </div>

            <div>
              <Calendar
                showAllEvents={true}
                culture='es'
                localizer={localizer}
                defaultView={'month'}
                events={events}
                startAccessor='beginsAt'
                endAccessor='endsAt'
                style={{ height: '70vh' }}
                messages={getMessagesES()}
                eventPropGetter={eventStyledGetter}
                components={{
                  eventWrapper: eventWrapperComponent,
                  event: e => CalendarEvent(e, selectedOption, objSite, objDoctor),
                  dateCellWrapper: DateCellWrapper,
                }}
                onSelectEvent={onSelect}
                onView={onViewChange}
                views={['month']}
                onNavigate={onNavigate}
              />
            </div>
          </>
        )}

        {objDoctor === '' && objSite === '' && optionalDoctor && (
          <div>
            <Calendar
              showAllEvents={true}
              culture='es'
              localizer={localizer}
              defaultView={'week'}
              events={events}
              startAccessor='beginsAt'
              endAccessor='endsAt'
              style={{ height: '70vh' }}
              messages={getMessagesES()}
              eventPropGetter={eventStyledGetter}
              components={{
                eventWrapper: eventWrapperComponent,
                event: e => CalendarEvent(e, selectedOption, objSite, objDoctor),
                dateCellWrapper: DateCellWrapper,
              }}
              onSelectEvent={onSelect}
              onView={onViewChange}
              views={views}
              onNavigate={onNavigate}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CalendarPage;
