import moment from 'moment';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

import { useGetMethod, usePostMethod } from '../../../Hooks';
import {formatteDocument, customSwaltAlertAsistencial, loader } from '../../../helpers';
import { formatNumberWithoutDecimals } from '../../../helpers/numberFormatting';

import ordBlueSearch from '../../../assets/img/icons/ordBlueSearch.svg';

import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';

import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

const paymentHeader = [
  { title: 'Forma de pago', className: 'px-2' },
  { title: 'Fecha', className: 'px-2' },
  { title: 'Banco/tarjeta', className: 'px-2 text-center' },
  { title: 'Valor', className: 'px-2 text-end' },
];

export default function SurgeryPaymentCancellation() {
  const siteId = useSelector(store => store.userReducer.site);
  const eaccount = useSelector(store => store.loginReducer.currentAccount.id);
  const userId = useSelector(store => store.loginReducer.user_data.id);
  const token = useSelector(store => store.loginReducer.Authorization);

  const [currentPayment, setCurrentPayment] = useState(null);
  const [sequence, setSequence] = useState(null);

  const { trigger: getPaymentInfo, load: paymentLoader } = useGetMethod();
  const { trigger: action, load: actionLoader } = usePostMethod();

  const calculateTotalServiceSelected = useMemo(() => {
    if (currentPayment) {
      return currentPayment.deposists.reduce((acc, el) => {
        return acc + Number(el.amount);
      }, 0);
    }
    return 0;
  }, [currentPayment]);

  const onGetPaymentInfo = async event => {
    event.preventDefault();

    const { sequenceValue } = event.target;
    const sequenceId = sequenceValue.value;

    if (sequenceId.length) {
      const result = await getPaymentInfo({
        url: `/surgery/payments/${sequenceId}/bill`,
        token: token,
        objFilters: { siteId, eaccount, userId },
        doAfterException: result => {
          customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: result.error,
            showCancelButton: false,
          });
          setCurrentPayment(null);
        },
      });

      if (result?.data && result.data[0]) {
        setCurrentPayment(result.data[0]);
        setSequence(sequenceId);
      }
    }
  };

  const onCancelPayment = () => {
    void customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Se realizará la anulación del abono',
    }).then(({ isConfirmed }) => {
      if (isConfirmed)
        void action({
          token: token,
          method: 'POST',
          body: { userId, eaccount, siteId, appId: currentPayment.surgeryId },
          url: `/surgery/payments/${sequence}/bill`,
          succesAction(result) {
            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Anulado exitosamente',
              text: result.message,
              showCancelButton: false,
            }).finally(() => {
              setCurrentPayment(null);
            });
          },
          doAfterException(result) {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: result.message,
              showCancelButton: false,
            })
          }
        });
    });
  };

  const renderComponent = () => {
    return (
      <>
        {(paymentLoader || actionLoader) && loader}
        <div className='container-fluid px-5'>
          <div className={`${tableStyles.ordDarkBlueText} fs-1 fw-bold py-4`}>
            Anulación de abono
          </div>
          <form
            onSubmit={onGetPaymentInfo}
            className={`w-50 d-flex ${tableStyles.appGrayInputSearch}`}
          >
            <input
              className={`w-100 ord-roundInput ${tableStyles.appInputDate}`}
              type='text'
              placeholder='Buscar no. de factura'
              name='sequenceValue'
            />
            <button type='submit' className='me-2'>
              <img width={16} src={ordBlueSearch} alt='buscar' />
            </button>
          </form>

          <div className='row mt-4'>
            <div className='col-lg-2'>
              <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Programación No.</div>
              <input
                type='text'
                className='w-100 ord-roundInput'
                value={currentPayment?.surgeryId || ''}
                disabled
              />
            </div>
            <div className='col-lg-2'>
              <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>
                Fecha de programación
              </div>
              <input
                type='text'
                className='w-100 ord-roundInput'
                value={
                  currentPayment?.surgeryDate
                    ? moment(currentPayment?.surgeryDate).format('DD/MM/YYYY')
                    : ''
                }
                disabled
              />
            </div>
            <div className='col'>
              <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Identificación</div>
              <input
                type='text'
                className='w-100 ord-roundInput'
                value={`${currentPayment?.patient.documentType.name || ''} ${formatteDocument(currentPayment?.patient.document).format || ''}`}
                disabled
              />
            </div>
            <div className='col-lg-5'>
              <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Nombre paciente</div>
              <input
                type='text'
                className='w-100 ord-roundInput'
                value={`${currentPayment?.patient.firstName || ''} ${currentPayment?.patient.lastName || ''} ${currentPayment?.patient?.secondSurname || ''}`}
                disabled
              />
            </div>
          </div>

          <div className='row my-2'>
            <div className='col-lg-4'>
              <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Empresa</div>
              <input
                type='text'
                className='w-100 ord-roundInput'
                value={currentPayment?.corporateClient || ''}
                disabled
              />
            </div>
            <div className='col-lg-3'>
              <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Plan-Contrato</div>
              <input
                type='text'
                className='w-100 ord-roundInput'
                value={currentPayment?.contract.name || ''}
                disabled
              />
            </div>
            <div className='col-lg-5'>
              <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Servicio</div>
              <input
                type='text'
                className='w-100 ord-roundInput'
                value={currentPayment?.service.name || ''}
                disabled
              />
            </div>
          </div>

          {currentPayment && (
            <>
              <div className={`${tableStyles.ordDarkBlueText} fs-4 mb-0 fw-bold mt-3`}>Recaudo</div>
              <div className='row'>
                <div className='col-lg-7'>
                  <OrdTable
                    shadow
                    headers={paymentHeader}
                    hasChildren={true}
                    oneHeadDarkBlue={false}
                    lowerCase={false}
                    footer={
                      <tr>
                        <td
                          colSpan={3}
                          className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}
                        >
                          Total
                        </td>
                        <td className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}>
                          {formatNumberWithoutDecimals(calculateTotalServiceSelected)}
                        </td>
                      </tr>
                    }
                  >
                    {currentPayment.deposists.length ? (
                      currentPayment.deposists.map((deposit, index) => (
                        <tr key={index}>
                          <td className='text-start px-2'>{deposit.depositType}</td>
                          <td className='text-start px-2'>
                            {moment(deposit.paymentDate).format('DD/MM/YYYY')}
                          </td>
                          <td className='text-center px-2'>{deposit.bank}</td>
                          <td className='text-end px-2'>{formatNumberWithoutDecimals(deposit.amount)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No se encontraron pagos realizados.</td>
                      </tr>
                    )}
                  </OrdTable>
                </div>
                <div className='col-lg-5'>
                  <div className='d-flex align-items-end h-100 justify-content-end'>
                    <button
                      className={`${tableStyles.tlnBtnPrimary} btn me-3`}
                      onClick={() => onCancelPayment()}
                      disabled={!currentPayment.deposists.length}
                    >
                      Anular
                    </button>
                    <button
                      className={`mr-2 btn ${tableStyles.tlnBtnPrimaryligth}`}
                      onClick={() => setCurrentPayment(null)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return renderComponent();
}
