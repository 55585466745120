import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import styles from './headerStyle.module.scss';
import { showAccountInfo } from '../../actions/headerActions';
import { logOutUser } from '../../actions/loginActions';
import { updateCellphone, updateUserInfo } from '../../actions/userActions';
import { saveCategory, setSite } from '../../actions/loginActions';
import headerIconLocation from '../../assets/img/header/headerIconLocation.svg';
import headerIconMail from '../../assets/img/header/headerIconMail.svg';
import headerIconPhone from '../../assets/img/header/headerIconPhone.svg';
import userLogo from '../../assets/img/header/user-img.png';
import BiowelOrd from '../../assets/img/icons/BiowelOrd.svg';
import accountSelectIcon from '../../assets/img/icons/accountSelectIcon.svg';
import Logo from '../../assets/img/icons/bioWel.svg';
import OpenLockIcon from '../../assets/img/icons/candado.svg';
import EditIcon from '../../assets/img/icons/editar.svg';
import iconCamera from '../../assets/img/icons/iconCamera.svg';
import siteIcon from '../../assets/img/icons/siteIconHeader.svg';
import mailIcon from '../../assets/img/icons/sobre.svg';
import PhoneIcon from '../../assets/img/icons/telefono.svg';
import whiteUpArrow from '../../assets/img/icons/whiteUpArrow.svg';
import logoTTN from '../../assets/img/logo_ttn.png';
import NotificationsPanel from './NotificationsPanel';
import ModalNew from '../Layouts/ModalNew';
import './header-style.scss';
import { loader, getExternalUrl } from '../../helpers';
import AccountSelectRender from '../AccountSelect/AccountSelectRender';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
      editPhone: true,
      editPassword: false,
      showHelp: false,
      showAccountSelect: false,
      shownModal: '',
      count: 0,
      user: {},
      site: null,
    };
  }
  componentDidMount() {
    const myUrl = window.location.pathname;

    this.getInitialState();
    this.props.saveCategory(this.updateCategory(myUrl.split('/')[1]), null);
  }
  componentDidUpdate(prevProps) {
    const myUrl = window.location.pathname;
    if (this.props.userData !== prevProps.userData) {
      this.getInitialState();
    }
    if (this.state.count === 15) {
      this.setState({ count: 0 });
      return Swal.fire({
        title: 'K.M.R Front-End Developer 😁',
        width: 600,
        padding: '3em',
        color: '#716add',
        background: '#fff url(https://sweetalert2.github.io/images/trees.png)',
        backdrop: `
          rgba(0,0,123,0.4)
          url("https://sweetalert2.github.io/images/nyan-cat.gif")
          left top
          no-repeat
        `,
      });
    }
    if (myUrl.split('/')[1] !== 'admision') {
      this.props.setSite();
    }
    if (prevProps.site !== this.props.site) {
      let { site } = this.state;
      site = this.props.site;
      this.setState({ site });
    }
  }
  updateCategory(category) {
    switch (category) {
      case 'compras':
        return 'Compras';
      case 'tesoreria':
        return 'Tesorería';
      case 'cartera':
        return 'Cartera';
      case 'administracion':
        return 'Administración';
      case 'contabilidad':
        return 'Contabilidad';
      case 'facturacion':
        return 'Facturación';
      case 'nomina':
        return 'Nómina';
      case 'gerencia':
        return 'Gerencia';
      case 'ordenamientos':
        return 'Ordenamientos';
      case 'asistencial':
        return 'Asistencial';
      case 'telemedicina':
        return 'Telemedicina';
      case 'citas':
        return 'Citas';
      case 'admision':
        return 'Admisión';
      case 'cuentasMedicas':
        return 'Cuentas médicas';
      case 'miNomina':
        return 'Mi nómina';
      default:
        return 'Configuración';
    }
  }

  getInitialState = () => {
    var userData = this.props.userData;
    if (userData) {
      let suport = {
        proyecto: 'BIOWEL',
        idusuario: userData?.id ?? '',
        rcc: '122',
        nom:
          (userData?.first_name ?? '') +
          ' ' +
          (userData.first_surname ?? '') +
          ' ' +
          (userData.second_surname ?? ''),
        tipdoc: 'CC',
        numdoc: '',
        email: userData?.email ?? '',
        tel: userData?.cellphone ?? '',
      };
      localStorage.setItem('suport', JSON.stringify(suport));
      this.setState({
        editProfile: false,
        editPhone: true,
        editPassword: false,
        showInputNewPassword: false,
        showButtons: false,
        showHelp: false,
        shownModal: '',
        user: {
          id: userData.id,
          first_name: userData.first_name,
          first_surname: userData.first_surname,
          second_surname: userData.second_surname,
          email: userData.email,
          cellphone: userData.cellphone,
          password: '',
          newPassword: '',
          newPasswordConfirm: '',
        },
      });
    }
  };
  handleToggleModal = whichone => {
    let currentstate = this.state;
    currentstate.shownModal = whichone;
    this.setState({
      currentstate,
    });
  };
  handleToggleHelp = () => {
    let currentstate = this.state;
    currentstate.showHelp = !currentstate.showHelp;
    this.setState({
      currentstate,
    });
  };

  onClickPopUp = () => {
    let showAccount = !this.props.showAccount;
    this.props.showAccountInfo(showAccount);
  };
  onClickLogOut = () => {
    this.props.logOutUser();
  };
  handleToggleProfile = () => {
    this.setState({
      editProfile: !this.state.editProfile,
      showButtons: !this.state.showButtons,
    });
  };
  handleTogglePassword = () => {
    this.setState({
      editProfile: false,
      editPassword: !this.state.editPassword,
      showInputNewPassword: !this.state.showInputNewPassword,
      showButtons: !this.state.showButtons,
    });
  };

  handleToggleAccount = () => {
    this.setState({
      showAccountSelect: !this.state.showAccountSelect,
    });
  };

  toggleDisabledInput = e => {
    const name = e.target.name;
    let { editPhone, editPassword, showInputNewPassword } = this.state;
    switch (name) {
      case 'editPhone':
        editPhone = !this.state.editPhone;
        break;
      case 'editPassword':
        editPassword = !this.state.editPassword;
        showInputNewPassword = !this.state.showInputNewPassword;
        break;
      default:
        break;
    }
    this.setState({ editPassword, editPhone, showInputNewPassword });
  };

  handleDisard = () => {
    this.getInitialState();
  };

  isAssistanceModule = () => {
    let value = false;
    window.location.pathname.includes('ordenamientos')
      ? (value = true)
      : window.location.pathname.includes('asistencial')
        ? (value = true)
        : window.location.pathname.includes('telemedicina')
          ? (value = true)
          : window.location.pathname.includes('citas')
            ? (value = true)
            : window.location.pathname.includes('admision')
              ? (value = true)
              : window.location.pathname.includes('cuentasMedicas')
                ? (value = true)
                : (value = false);
    return value;
  };

  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { user } = this.state;
    user[name] = value;
    this.setState({ user });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.getInitialState();
    if (this.state.editPassword) {
      return this.props.updateUserInfo(this.state.user);
    } else {
      return this.props.updateCellphone(this.state.user);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.getInitialState();
    if (this.state.editPassword) {
      return this.props.updateUserInfo(this.state.user);
    } else {
      return this.props.updateCellphone(this.state.user);
    }
  };

  render() {
    return (
      <>
        {this.props.loading && loader}
        <div
          className={`${this.isAssistanceModule() ? styles.ordHeaderContainer : styles.headerContainer} w-100`}
        >
          <Link
            to={
              window.location.pathname === '/accountselect' ? '/accountselect' : '/categoryselect'
            }
          >
            <img
              onClick={() => this.setState({ count: this.state.count + 1 })}
              className='logo-header m-3'
              src={this.isAssistanceModule() ? BiowelOrd : Logo}
              alt='logo foca header'
            />
          </Link>
          {window.location.pathname === '/accountselect' ? (
            <div className='text-section'>
              <p className='text-section-style'>• Cuentas</p>
            </div>
          ) : window.location.pathname === '/categoryselect' ? (
            <div className='text-section'>
              <p className='mr-1 text-section-style'>• Home</p>
            </div>
          ) : (
            <div className='text-section'>
              <p className='mr-1 text-section-style'>• {this.props.category}</p>
            </div>
          )}

          {(window.location.pathname === '/citas/SolicitarDescuentos' ||
            window.location.pathname === '/citas/AutorizacionDeDescuentos') &&
            this?.props?.category === 'Citas' && <NotificationsPanel />}

          {!!this.props.site && this?.props?.category === 'Admisión' && (
            <div className='px-2'>
              <img alt='siteIcon' src={siteIcon} />
              <span className={`${styles.tlnTextGray} px-2`}>{this.props.siteName}</span>
            </div>
          )}

          {this.props.isAuth && (
            <div className='right-side-icons'>
              <div
                className={`${styles.hoverPointer} ${styles.userImgHeaderButton}`}
                onClick={this.onClickPopUp}
              >
                {!!this.props.currentAccount && (
                  <img
                    className={`${styles.userImgHeaderIcon} `}
                    src={accountSelectIcon}
                    alt='imagen usuario header'
                  />
                )}
                <div className={`${styles.userImgHeaderText}`}>
                  {this.props.currentAccount?.name}
                </div>
                <img
                  className={`${styles.userImgHeader} `}
                  src={this.props.userData.image ?? userLogo}
                  alt='imagen usuario header'
                />
              </div>
              {this.props.showAccount && (
                <div className={styles.popUpUserProfile}>
                  <img
                    className={`${styles.thatTinyArrowUpThere}`}
                    src={whiteUpArrow}
                    alt='ignore meeeee'
                  />

                  <div className='d-flex flex-column mx-auto'>
                    <div className={styles.profilepicWrapper}>
                      <img
                        className={`${styles.profilepic} ${styles.hoverPointer} `} //w-25 mx-3 my-3 h-25 pb-3
                        src={this.props.userData.image ?? userLogo}
                        alt='imagen usuario header'
                        onClick={this.onClickPopUp}
                      />
                      <img
                        className={`${styles.changepfp} ${styles.hoverPointer} `}
                        src={iconCamera}
                        alt='cambiar imagen'
                      />
                    </div>

                    <h5 className={`${styles.userName} text-capitalize`}>
                      {this.state.user?.first_name} {this.state.user?.first_surname}{' '}
                      {this.state.user?.second_surname}
                    </h5>

                    {!!this.props.currentAccount && (
                      <h6 className={`${styles.userAccountProfile} text-capitalize`}>
                        {this.props.currentAccount?.name} -{' '}
                        {this.props.currentAccount?.profile?.nombre}
                      </h6>
                    )}

                    {this.props.currentAccount && (
                      <span
                        className={`${styles.optionText}  ${styles.hoverPointer}`}
                        onClick={() => {
                          this.handleToggleModal('ACCOUNT');
                          this.onClickPopUp();
                        }}
                      >
                        Cambiar Rol y Cuenta
                      </span>
                    )}
                    {this.props.currentAccount && (
                      <Link
                        className={`${styles.optionText} ${styles.linkText}`}
                        to={'#'}
                        onClick={() => {
                          window.location.replace(
                            getExternalUrl({
                              myPayroll: true,
                              loginReducer: this.props.loginReducer,
                              category: {
                                ...this.props.myPayrollModule,
                                url: `${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE_PAYROLL}`,
                              },
                            })
                          )
                        }}
                      >
                        Mi nómina
                      </Link>
                    )}

                    {this.props.currentAccount && (
                      <Link
                        to={'#'}
                        className={`text-decoration-none `}
                      >
                        <p
                          className={`${styles.optionText}  ${styles.hoverPointer}`}
                          onClick={() => {
                            window.location.replace(
                              getExternalUrl({
                                mySetting: true,
                                loginReducer: this.props.loginReducer,
                                category: {
                                  ...this.props.myPayrollModule,
                                  url: `${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE_PAYROLL}`,
                                },
                              })
                            );
                          }}
                        >
                          Configuración
                        </p>
                      </Link>
                    )}

                    <span
                      className={`${styles.optionText} ${styles.hoverPointer}`}
                      onClick={() => {
                        this.handleToggleModal('HELP');
                        this.onClickPopUp();
                      }}
                    >
                      Ayuda
                    </span>

                    <span
                      className={`${styles.optionText} ${styles.hoverPointer}`}
                      onClick={this.onClickLogOut}
                    >
                      Cerrar sesión
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Profile info */}
          {this.state.shownModal === 'PROFILE' && (
            // <div className="d-flex flex-column mx-3 pb-5">
            <ModalNew
              title='Configuración'
              show={this.state.shownModal === 'PROFILE'}
              size='md'
              onHide={this.handleDisard}
              btnYesEvent={this.handleSubmit}
              btnYesName='Guardar'
              btnNoName='Cancelar'
            >
              <div
                className={`input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}
              >
                <img
                  src={mailIcon}
                  alt='User icon'
                  className={`input-group-prepend ${styles.inputUserIconEditProfile}`}
                />
                <input
                  type='email'
                  placeholder='USUARIO'
                  name='username'
                  className={`form-control p-3 border-0 ${styles.inputTextHeader}`}
                  value={this.state.user.email}
                  // onChange={this.onChange}
                  aria-describedby='basic-addon1'
                  aria-label='Usuario'
                  disabled
                />
              </div>
              <div
                className={`input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent ${styles.containerInputHeader}`}
              >
                <img
                  src={PhoneIcon}
                  alt='User icon'
                  className={`input-group-prepend ${styles.inputUserIconEditProfile}`}
                />
                <input
                  type='number'
                  placeholder='Teléfono'
                  name='cellphone'
                  className={`form-control p-3 border-0 ${styles.inputTextHeader} ${styles.inputEditMaxWidth}`}
                  value={this.state.user.cellphone}
                  onChange={this.onChange}
                  aria-describedby='basic-addon1'
                  aria-label='telefono'
                  disabled={this.state.editPhone ? 'disabled' : ''}
                />
                <span
                  onClick={this.toggleDisabledInput}
                  className={`${styles.hoverPointer} ${styles.borderEditInput}`}
                >
                  <img
                    src={EditIcon}
                    alt='Edit icon'
                    name='editPhone'
                    className={`input-group-prepend ${styles.inputEditIconProfile}`}
                  />
                </span>
              </div>

              <span
                className={`${styles.optionText}  ${styles.hoverPointer}`}
                onClick={() => this.handleToggleModal('PASS')}
              >
                Cambiar contraseña
              </span>
            </ModalNew>
          )}

          {this.state.shownModal === 'PASS' && (
            <ModalNew
              title='Cambiar Contraseña'
              show={this.state.shownModal === 'PASS'}
              size='md'
              onHide={this.handleDisard}
              btnYesEvent={this.handleSubmit}
              btnYesName='Guardar'
              btnNoName='Cancelar'
            >
              <div
                className={`d-flex input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}
              >
                <img
                  src={OpenLockIcon}
                  alt='candado icon'
                  className={`input-group-prepend ${styles.inputUserIconEditProfile}`}
                />
                <input
                  type='password'
                  placeholder='Contraseña actual'
                  name='password'
                  className={`form-control p-3  border-0 ${styles.inputTextHeader}`}
                  value={this.state.user.password}
                  onChange={this.onChange}
                  aria-describedby='basic-addon1'
                  aria-label='contraseña actual'
                />
              </div>

              <div
                className={`d-flex input-group ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}
              >
                <img
                  src={OpenLockIcon}
                  alt='candado icon'
                  className={`input-group-prepend ${styles.inputUserIconEditProfile}`}
                />
                <input
                  type='password'
                  placeholder='Nueva contraseña'
                  name='newPassword'
                  className={`form-control p-3  border-0 ${styles.inputTextHeader}`}
                  value={this.state.user.newPassword}
                  onChange={this.onChange}
                  aria-describedby='basic-addon1'
                  aria-label='nueva contraseña'
                />
              </div>

              <div
                className={`d-flex input-group mb-5 ${styles.inputBlueBorder} mt-3 w-100 mx-auto bg-transparent`}
              >
                <img
                  src={OpenLockIcon}
                  alt='candado icon'
                  className={`input-group-prepend ${styles.inputUserIconEditProfile}`}
                />
                <input
                  type='password'
                  placeholder='Confirmar contraseña'
                  name='newPasswordConfirm'
                  className={`form-control p-3  border-0 ${styles.inputTextHeader}`}
                  value={this.state.user.newPasswordConfirm}
                  onChange={this.onChange}
                  aria-describedby='basic-addon1'
                  aria-label='nueva contraseña'
                />
              </div>
            </ModalNew>
          )}

          {this.state.shownModal === 'HELP' && (
            <ModalNew
              title='Ayuda'
              show={this.state.shownModal === 'HELP'}
              size='sm2'
              hideCancelButton={true}
              btnYesName='Aceptar'
              btnYesEvent={() => this.handleToggleModal('')}
              onHide={() => this.handleToggleModal('')}
            >
              <div className={`d-flex flex-column`}>
                <img className={styles.auydaLogo} src={logoTTN} alt='TTN' />

                <p className={styles.ayudaSub}>Información de Contacto</p>

                <div className={`${styles.ayudaRow}`}>
                  <img src={headerIconPhone} alt='headerIconPhone' />
                  <p>+57 300 658 32 65</p>
                </div>

                <div className={`${styles.ayudaRow}`}>
                  <img src={headerIconMail} alt='headerIconMail' />
                  <p>analistadesoporte@ttncompany.com</p>
                </div>

                <div className={`${styles.ayudaRow}`}>
                  <img src={headerIconLocation} alt='headerIconLocation' />
                  <p> Carrera 51b # 87 - 50 Piso 3 Local 337</p>
                </div>
              </div>
            </ModalNew>
          )}

          <ModalNew
            title='Seleccionar Cuenta'
            show={this.state.shownModal === 'ACCOUNT'}
            size='xl'
            hideCancelButton={true}
            btnYesName='Aceptar'
            btnYesEvent={() => this.handleToggleModal('')}
            onHide={() => this.handleToggleModal('')}
          >
            <AccountSelectRender afterClick={() => this.handleToggleModal('')} />
          </ModalNew>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  loginReducer: state.loginReducer,
  isAuth: state.loginReducer.Authorization,
  currentAccount: state.loginReducer.currentAccount,
  userData: state.userReducer.user_data,
  showAccount: state.headerReducer.showAccount,
  category: state.loginReducer.category,
  site: state.userReducer.site,
  siteName: state.userReducer.siteName,
  loading: state.loginReducer.loading,
  myPayrollModule: state.loginReducer?.all_categories?.find(el => el.prefix === 'my_payroll'),
  all_data: state.loginReducer,
});

const mapDispatchToProps = dispatch => ({
  showAccountInfo: status => {
    dispatch(showAccountInfo(status));
  },
  updateUserInfo: user => {
    dispatch(updateUserInfo(user));
  },
  updateCellphone: user => {
    dispatch(updateCellphone(user));
  },
  logOutUser: () => {
    dispatch(logOutUser());
  },
  saveCategory: (category, doAfter) => {
    dispatch(saveCategory(category, doAfter));
  },
  setSite: () => {
    dispatch(setSite());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
